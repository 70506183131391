import { StringifyOptions } from "querystring";
import API from "./config";
import endpoints from "./endpoints";
import {
  APICalculateTransactionPayload,
  APICalculateTransactionResponse,
  APICancelTransactionResponse,
  APIChangePasswordResponse,
  APIChangeProductQuotaPayload,
  APIChangeProductQuotaResponse,
  APICheckoutDetailByProductIdResponse,
  APIConfirmTransactionResponse,
  APICreateCompanyBodyPayload,
  APICreateCompanyBodyPayloadV2,
  APICreateNewTransactionPayload,
  APICreateNewTransactionResponse,
  APICreateProductBodyPayload,
  APICreateProductResponse,
  APICreateWithdrawResponse,
  APIDeleteProductByIdResponse,
  APIDownloadTransactionCSVResponse,
  APIEditCompanyBodyPayload,
  APIEditCompanyBodyPayloadV2,
  APIGetByDomainNameResponse,
  APIGetCompanyAccountResponse,
  APIGetCompanyV2Response,
  APIGetDomainAvailabilityResponse,
  APIGetProductByIdResponse,
  APIGetProductCompactListResponse,
  APIGetProductLinkByIdResponse,
  APIGetProductReservationByIdResponse,
  APIGetPublicProductById,
  APIGetPublicProductScheduleDetailByIdResponse,
  APIGetPublicTransactionByIdResponse,
  APIGetScheduleDetailsByProductIdResponse,
  APIGetTransactionByIdResponse,
  APIGetTransactionQueries,
  APIGetTransactionResponse,
  APIGetUpcomingSchedulesResponse,
  APIGetWithdrawListResponse,
  APIProductByTransactionResponse,
  APIRefundTransactionPayload,
  APIRefundTransactionResponse,
  APIRequestForgotPasswordResponse,
  APIResendBankAccountResponse,
  APIResendVerificationEmailResponse,
  APIResendVerificationEmailWithdrawResponse,
  APIResetPasswordResponse,
  APITotalTransactionByDateResponse,
  APITransactionByStatusResponse,
  APITransactionOverviewResponse,
  APIUpdateCompanyProductDetailBodyPayload,
  APIUpdateCompanyV2Response,
  APIUpdateProductByIdPayload,
  APIUpdateProductByIdResponse,
  APIUploadCompanyLogoPayload,
  APIUploadProductPhotosPayload,
  APIUploadProductPhotosResponse,
  APIUploadStorageResponse,
  APIUpsertBankAccountResponse,
  APIVerifyBankAccountResponse,
  APIVerifyEmailResponse,
  APIVerifyWithdrawResponse,
  ChangePasswordRequest,
  CompanyByIdResponse,
  CreateCompanyResponse,
  CreateProductLinkRequest,
  CreateProductReservationRequest,
  CreateProductSectionRequest,
  CreateWithdrawRequest,
  DashboardBaseQuery,
  DownloadSignedUrlResponse,
  FindUserByIdResponse,
  GoogleLoginResponse,
  LoginGooglePayload,
  LoginPayload,
  LoginResponse,
  PRODUCT_TYPE,
  RegisterPayload,
  RegisterResponse,
  ResetPasswordRequest,
  UpcomingScheduleQuery,
  UpdateCompanyProductDetailResponse,
  UpdateProductLinkRequest,
  UpdateProductReservationRequest,
  UpdateProductSectionRequest,
  UploadCompanyLogoResponse,
  UploadStoragePayload,
  UpsertBankAccountRequest,
  UserCompaniesResponse,
  VerifyBankAccountRequest,
  VerifyEmailRequest,
  VerifyWithdrawRequest,
} from "./request.types";

export const registerAPI = (data: RegisterPayload) => {
  return API<RegisterResponse>({
    method: "POST",
    url: endpoints.REGISTER,
    data,
  });
};
export const loginAPI = (data: LoginPayload) => {
  return API<LoginResponse>({
    method: "POST",
    url: endpoints.LOGIN,
    data,
  });
};

export const loginGoogleAPI = (data: LoginGooglePayload) => {
  return API<GoogleLoginResponse>({
    method: "POST",
    url: endpoints.LOGIN_GOOGLE,
    data,
  });
};

export const requestTokenAPI = () => {
  return API({
    method: "POST",
    url: endpoints.REFRESH_TOKEN,
  });
};

export const logoutAPI = () => {
  return API({
    method: "POST",
    url: endpoints.LOGOUT,
  });
};

export const findUserByIdAPI = (userId: string) => {
  return API<FindUserByIdResponse>({
    method: "GET",
    url: endpoints.BASE_URL + "/" + userId,
  });
};

export const createCompanyAPI = (data: APICreateCompanyBodyPayload) => {
  return API<CreateCompanyResponse>({
    method: "POST",
    url: `${endpoints.COMPANY}/`,
    data,
  });
};

export const createCompanyAPIV2 = (data: APICreateCompanyBodyPayloadV2) => {
  return API<CreateCompanyResponse>({
    method: "POST",
    url: `${endpoints.COMPANY_V2}/`,
    data,
  });
};
export const updateCompanyAPI = (
  companyId: string,
  data: APIEditCompanyBodyPayload
) => {
  return API<CreateCompanyResponse>({
    method: "PUT",
    url: `${endpoints.COMPANY}/${companyId}`,
    data,
  });
};

export const updateCompanyProductDetailAPI = (
  companyId: string,
  data: APIUpdateCompanyProductDetailBodyPayload
) => {
  return API<UpdateCompanyProductDetailResponse>({
    method: "PUT",
    url: `${endpoints.COMPANY_V2}/${companyId}/detail`,
    data,
  });
};

export const createProductAPI = (data: APICreateProductBodyPayload) => {
  return API<APICreateProductResponse>({
    method: "POST",
    url: endpoints.PRODUCT,
    data,
  });
};

export const getProductById = (productId: string) => {
  return API<APIGetProductByIdResponse>({
    method: "GET",
    url: `${endpoints.PRODUCT}/${productId}`,
  });
};

export const deleteProductById = (productId: string, type?: PRODUCT_TYPE) => {
  switch (type) {
    case PRODUCT_TYPE.LINK:
      return API<APIDeleteProductByIdResponse>({
        method: "DELETE",
        url: `${endpoints.PRODUCT_LINK}/${productId}`,
      });
    case PRODUCT_TYPE.RESERVATION:
      return API<APIDeleteProductByIdResponse>({
        method: "DELETE",
        url: `${endpoints.PRODUCT_RESERVATION}/${productId}`,
      });
    case PRODUCT_TYPE.SECTION:
      return API<APIDeleteProductByIdResponse>({
        method: "DELETE",
        url: `${endpoints.PRODUCT_SECTION}/${productId}`,
      });
    default:
      return API<APIDeleteProductByIdResponse>({
        method: "DELETE",
        url: `${endpoints.PRODUCT}/${productId}`,
      });
  }
};
export const uploadCompanyLogo = (data: APIUploadCompanyLogoPayload) => {
  const formData = new FormData();
  formData.append("file", data.file);
  return API<UploadCompanyLogoResponse>({
    method: "POST",
    url: endpoints.COMPANY_LOGO + `/${data.companyId}`,
    data: formData,
  });
};
export const downloadSignedUrl = (path: string) => {
  return API<DownloadSignedUrlResponse>({
    method: "GET",
    url: endpoints.STORAGE_DOWNLOAD,
    data: {
      filePath: path,
    },
  });
};

export const getCompanyById = (companyId: string) => {
  return API<CompanyByIdResponse>({
    method: "GET",
    url: endpoints.COMPANY + `/${companyId}`,
  });
};
export const getUserCompanies = () => {
  return API<UserCompaniesResponse>({
    method: "GET",
    url: endpoints.COMPANY,
  });
};

export const uploadProductPhotos = (
  data: APIUploadProductPhotosPayload,
  companyId: string,
  productId: string
) => {
  const formData = new FormData();
  data.forEach((file) => {
    formData.append("file", file);
  });
  return API<APIUploadProductPhotosResponse>({
    method: "POST",
    url: `${endpoints.PRODUCT_STORAGE_UPLOAD}/${companyId}/${productId}`,
    data: formData,
  });
};

export const createProduct = (data: APICreateProductBodyPayload) => {
  return API<APICreateProductResponse>({
    method: "POST",
    url: endpoints.PRODUCT,
    data,
  });
};

export const getDataByDomainName = (domainName: string) => {
  return API<APIGetByDomainNameResponse>({
    method: "GET",
    url: endpoints.COMPANY_BY_DOMAIN + `/${domainName}`,
  });
};

export const getDomainAvailabiity = (domainName: string) => {
  return API<APIGetDomainAvailabilityResponse>({
    method: "GET",
    url: endpoints.CHECK_DOMAIN_AVAILABILITY + `/${domainName}`,
  });
};

export const getPublicProductById = (productId: string) => {
  return API<APIGetPublicProductById>({
    method: "GET",
    url: endpoints.GET_PUBLIC_PRODUCT_BY_ID + `/${productId}`,
  });
};

export const getPublicProductScheduleById = (
  productId: string,
  date: string
) => {
  return API<APIGetPublicProductScheduleDetailByIdResponse>({
    method: "GET",
    url: `${endpoints.GET_PUBLIC_PRODUCT_BY_ID}/${productId}/${date}`,
  });
};

export const getPublicProductScheduleByIdV2 = (
  productId: string,
  date: string
) => {
  return API<APIGetPublicProductScheduleDetailByIdResponse>({
    method: "GET",
    url: `${endpoints.GET_PUBLIC_PRODUCT_RESERVATION_BY_ID}/${productId}/${date}`,
  });
};

export const getScheduleDetailsByProductId = (
  productId: string,
  date: string
) => {
  return API<APIGetScheduleDetailsByProductIdResponse>({
    method: "GET",
    url: `${endpoints.GET_SCHEDULE_DETAILS_BY_PRODUCT_ID}/${productId}/${date}`,
  });
};
export const getScheduleDetailsByProductIdV2 = (
  productId: string,
  date: string
) => {
  return API<APIGetScheduleDetailsByProductIdResponse>({
    method: "GET",
    url: `${endpoints.GET_SCHEDULE_DETAILS_BY_PRODUCT_ID_V2}/${productId}/${date}`,
  });
};

export const getProductCompactList = (companyId: string) => {
  return API<APIGetProductCompactListResponse>({
    method: "GET",
    url: `${endpoints.GET_PRODUCT_COMPACT_LIST}/${companyId}`,
  });
};

export const getProductReservationCompactList = (companyId: string) => {
  return API<APIGetProductCompactListResponse>({
    method: "GET",
    url: `${endpoints.GET_PRODUCT_RESERVATION_COMPACT_LIST}/${companyId}`,
  });
};
// {
//   "companyID": "da7b99fb-592c-4ffd-b104-d2a70579fa4f",
//   "date": "2024-08-30",
//   "productDetail": [
//       {
//           "productID": "9747a169-f94b-4750-8003-ea9b47eb270c",
//           "scheduleDetails": [
//               {
//                   "startTime": "08.00",
//                   "endTime": "09.00",
//                   "date": "2024-08-30",
//                   "qty": 2
//               },
//               {
//                   "startTime": "17.00",
//                   "endTime": "18.00",
//                   "date": "2024-08-30",
//                   "qty": 1
//               }
//           ]
//       }
//   ]
// }
export const getCalculateTransaction = (
  data: APICalculateTransactionPayload
) => {
  return API<APICalculateTransactionResponse>({
    method: "POST",
    url: endpoints.PUBLIC_CALCULATE_TRANSACTION,
    data,
  });
};

export const createNewTransaction = (data: APICreateNewTransactionPayload) => {
  return API<APICreateNewTransactionResponse>({
    method: "POST",
    url: endpoints.PUBLIC_CREATE_NEW_TRANSACTION,
    data,
  });
};

export const cancelTransaction = (transactionId: string) => {
  return API<APICancelTransactionResponse>({
    method: "POST",
    url: `${endpoints.PUBLIC_CANCEL_TRANSACTION}/${transactionId}`,
  });
};
export const confirmTransaction = (transactionId: string) => {
  return API<APIConfirmTransactionResponse>({
    method: "POST",
    url: `${endpoints.PUBLIC_CONFIRM_TRANSACTION}/${transactionId}`,
  });
};

export const refundTransaction = (data: APIRefundTransactionPayload) => {
  return API<APIRefundTransactionResponse>({
    method: "POST",
    url: `${endpoints.PUBLIC_REFUND_TRANSACTION}/${data.transactionID}`,
    data,
  });
};
export const getPublicTransactionId = (
  companyDomainName: string,
  transactionId: string
) => {
  return API<APIGetPublicTransactionByIdResponse>({
    method: "GET",
    url: `${endpoints.PUBLIC_GET_TRANSACTION_BY_ID}/${companyDomainName}/${transactionId}`,
  });
};

export const changeProductQuota = (
  productId: string,
  data: APIChangeProductQuotaPayload
) => {
  return API<APIChangeProductQuotaResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT}/${productId}/quota`,
    data,
  });
};

export const changeProductQuotaV2 = (
  productId: string,
  data: APIChangeProductQuotaPayload
) => {
  return API<APIChangeProductQuotaResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT_RESERVATION}/${productId}/quota`,
    data,
  });
};
export const getTransactionByQuery = (query: APIGetTransactionQueries) => {
  return API<APIGetTransactionResponse>({
    method: "GET",
    url: `${endpoints.TRANSACTION}`,
    params: query,
  });
};

export const getTransactionById = (transactionId: string) => {
  return API<APIGetTransactionByIdResponse>({
    method: "GET",
    url: `${endpoints.TRANSACTION}/${transactionId}`,
  });
};

export const updateProductById = (
  productId: string,
  data: APIUpdateProductByIdPayload
) => {
  return API<APIUpdateProductByIdResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT}/${productId}`,
    data,
  });
};
export const storageUpload = (payload: UploadStoragePayload) => {
  const formData = new FormData();
  formData.append("file", payload.image);
  return API<APIUploadStorageResponse>({
    method: "POST",
    url: endpoints.STORAGE_UPLOAD + `/${payload.usage}/${payload.companyId}`,
    data: formData,
  });
};

export const getCompanyByIdV2 = (companyId: string) => {
  return API<APIGetCompanyV2Response>({
    method: "GET",
    url: endpoints.COMPANY_V2 + `/${companyId}`,
  });
};

export const getPublicCompanyDomainV2 = (companyDomainName: string) => {
  return API<APIGetCompanyV2Response>({
    method: "GET",
    url: endpoints.PUBLIC_COMPANY_V2 + `/${companyDomainName}`,
  });
};
export const getPublicProductReservationByMonth = (
  productId: string,
  monthYear: string
) => {
  return API<string[]>({
    method: "GET",
    url:
      endpoints.GET_PUBLIC_PRODUCT_RESERVATION_BY_ID +
      `/month/${productId}/${monthYear}`,
  });
};

export const updateCompanyAPIV2 = (
  companyId: string,
  payload: APIEditCompanyBodyPayloadV2
) => {
  return API<APIUpdateCompanyV2Response>({
    method: "PUT",
    url: endpoints.COMPANY_V2 + `/${companyId}`,
    data: payload,
  });
};

export const createProductReservationV2 = (
  data: CreateProductReservationRequest
) => {
  return API<APICreateProductResponse>({
    method: "POST",
    url: endpoints.PRODUCT_RESERVATION,
    data,
  });
};

export const createProductLinkV2 = (data: CreateProductLinkRequest) => {
  return API<APICreateProductResponse>({
    method: "POST",
    url: endpoints.PRODUCT_LINK,
    data,
  });
};

export const upsertBankAccount = (
  companyId: string,
  data: UpsertBankAccountRequest
) => {
  return API<APIUpsertBankAccountResponse>({
    method: "POST",
    url: endpoints.COMPANY_ACCOUNT + `/${companyId}`,
    data,
  });
};
export const verifyBankAccount = (
  companyId: string,
  data: VerifyBankAccountRequest
) => {
  return API<APIVerifyBankAccountResponse>({
    method: "POST",
    url: endpoints.COMPANY_ACCOUNT + `/verify/${companyId}`,
    data,
  });
};
export const resendBankAccount = (companyId: string) => {
  return API<APIResendBankAccountResponse>({
    method: "POST",
    url: endpoints.COMPANY_ACCOUNT + `/resend/${companyId}`,
  });
};

export const createWithdraw = (
  companyId: string,
  data: CreateWithdrawRequest
) => {
  return API<APICreateWithdrawResponse>({
    method: "POST",
    url: endpoints.WITHDRAW + `/${companyId}`,
    data,
  });
};

export const verifyWithdraw = (
  companyId: string,
  data: VerifyWithdrawRequest
) => {
  return API<APIVerifyWithdrawResponse>({
    method: "POST",
    url: endpoints.WITHDRAW + `/verify/${companyId}`,
    data,
  });
};

export const resendWithdrawEmail = (companyId: string) => {
  return API<APIResendVerificationEmailWithdrawResponse>({
    method: "POST",
    url: endpoints.WITHDRAW + `/resend/${companyId}`,
  });
};

export const createProductSectionV2 = (data: CreateProductSectionRequest) => {
  return API<APICreateProductResponse>({
    method: "POST",
    url: endpoints.PRODUCT_SECTION,
    data,
  });
};

export const updateProductReservationV2 = (
  data: Partial<UpdateProductReservationRequest>,
  productId: string
) => {
  return API<APICreateProductResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT_RESERVATION}/${productId}`,
    data,
  });
};

export const updateProductLinkV2 = (
  data: Partial<UpdateProductLinkRequest>,
  productId: string
) => {
  return API<APICreateProductResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT_LINK}/${productId}`,
    data,
  });
};

export const updateProductSectionV2 = (
  data: UpdateProductSectionRequest,
  productId: string
) => {
  return API<APICreateProductResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT_SECTION}/${productId}`,
    data,
  });
};

export const getProductReservationById = (productId: string) => {
  return API<APIGetProductReservationByIdResponse>({
    method: "GET",
    url: `${endpoints.PRODUCT_RESERVATION}/${productId}`,
  });
};

export const getProductLinkById = (productId: string) => {
  return API<APIGetProductLinkByIdResponse>({
    method: "GET",
    url: `${endpoints.PRODUCT_LINK}/${productId}`,
  });
};

export const getCheckoutDetailByProductId = (productId: string) => {
  return API<APICheckoutDetailByProductIdResponse>({
    method: "GET",
    url: `${endpoints.PUBLIC_GET_PRODUCT_RESERVATION}/${productId}`,
  });
};

export const requestForgotPassword = (email: string) => {
  return API<APIRequestForgotPasswordResponse>({
    method: "POST",
    url: `${endpoints.REQUEST_FORGOT_PASSWORD}`,
    data: { email },
  });
};

export const resetPassword = (data: ResetPasswordRequest) => {
  return API<APIResetPasswordResponse>({
    method: "POST",
    url: `${endpoints.RESET_PASSWORD}`,
    data,
  });
};

export const changePassword = (data: ChangePasswordRequest) => {
  return API<APIChangePasswordResponse>({
    method: "POST",
    url: `${endpoints.CHANGE_PASSWORD}`,
    data,
  });
};

export const verifyEmail = (data: VerifyEmailRequest) => {
  return API<APIVerifyEmailResponse>({
    method: "POST",
    url: `${endpoints.VERIFY_EMAIL}`,
    data,
  });
};

export const resendVerificationEmail = (email: string) => {
  return API<APIResendVerificationEmailResponse>({
    method: "POST",
    url: `${endpoints.RESEND_VERIFICATION_EMAIL}`,
    data: { email },
  });
};
export const downloadTransactionCSV = (query: APIGetTransactionQueries) => {
  return API<APIDownloadTransactionCSVResponse>({
    method: "GET",
    url: `${endpoints.DOWNLOAD_TRANSACTION_CSV}`,
    params: query,
  });
};

export const totalTransactionPerDate = (data: DashboardBaseQuery) => {
  return API<APITotalTransactionByDateResponse>({
    method: "POST",
    url: `${endpoints.TOTAL_TRANSACTION_PER_DATE}`,
    data,
  });
};

export const totalTransactionByStatus = (data: DashboardBaseQuery) => {
  return API<APITransactionByStatusResponse>({
    method: "POST",
    url: `${endpoints.TOTAL_TRANSACTION_BY_STATUS}`,
    data,
  });
};

export const totalProductByTransaction = (data: DashboardBaseQuery) => {
  return API<APIProductByTransactionResponse>({
    method: "POST",
    url: `${endpoints.PRODUCT_BY_TRANSACTION}`,
    data,
  });
};

export const transactionOverview = (data: DashboardBaseQuery) => {
  return API<APITransactionOverviewResponse>({
    method: "POST",
    url: `${endpoints.TRANSACTION_OVERVIEW}`,
    data,
  });
};

export const upcomingSchedules = (data: UpcomingScheduleQuery) => {
  return API<APIGetUpcomingSchedulesResponse>({
    method: "POST",
    url: `${endpoints.UPCOMING_SCHEDULES}`,
    data,
  });
};

export const getCompanyAccountByID = (companyId: string) => {
  return API<APIGetCompanyAccountResponse>({
    method: "GET",
    url: endpoints.COMPANY_ACCOUNT + `/${companyId}`,
  });
};
export const getWithdrawList = (companyId: string) => {
  return API<APIGetWithdrawListResponse>({
    method: "GET",
    url: endpoints.WITHDRAW + `/${companyId}`,
  });
};
