import React, { useMemo, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import MobileContainer from "../../components/Layout/MobileContainer/MobileContainer";
import { COLOR } from "../../utils/color";
import { FaWallet } from "react-icons/fa";
import { formatCurrency } from "../../utils/helper";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts";
import { RiBillLine } from "react-icons/ri";
import {
  useGetCompanyByIdV2,
  useGetProductByTransaction,
  useGetTotalTransactionPerDate,
  useGetTransactionByQuery,
  useGetTransactionByStatus,
  useGetTransactionOverview,
  useGetUpcomingSchedules,
} from "../../query/queries";
import {
  DashboardBaseQuery,
  TransactionBaseQuery,
  UpcomingScheduleQuery,
  UpcomingScheduleStatus,
} from "../../api/request.types";
import { RootReducerState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import DateRangePicker from "../../components/Forms/DateRangePicker/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import EventItem from "../../components/EventItem/EventItem";
import OpacityButton from "../../components/OpacityButton/OpacityButton";
import {
  ROUTE_NAME,
  RoutePath,
  TIMEZONE_SHORTLABEL,
  TIMEZONES,
} from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import { TbBrandGoogleAnalytics, TbDeviceAnalytics } from "react-icons/tb";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { SiGoogleanalytics } from "react-icons/si";
import MenuButton from "../../components/MenuButton/MenuButton";
import TransactionIcon from "../../assets/svg/TransactionIcon";
import ProductIcon from "../../assets/svg/ProductIcon";

const Home: React.FC = () => {
  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const companyId = userReducer.data.companyId || "";
  const navigate = useNavigate();
  const today = dayjs();
  const [dateRange, setDateRange] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>({ startDate: dayjs().startOf("weeks"), endDate: dayjs().endOf("weeks") });

  const getCompanyById = useGetCompanyByIdV2(companyId, {
    enabled: true,
  });
  const companyDetail = getCompanyById.data?.data.data;

  const BUTTON_LIST = useMemo(() => {
    return [
      {
        IconElement: TransactionIcon,
        primaryColor: COLOR.danger500,
        secondaryColor: COLOR.danger50,
        id: "transaction-list",
        onClick: () => {
          navigate(RoutePath[ROUTE_NAME.TRANSACTION]);
        },
      },
      {
        IconElement: ProductIcon,
        primaryColor: COLOR.success500,
        secondaryColor: COLOR.success50,
        id: "product",
        onClick: () => {
          navigate(RoutePath[ROUTE_NAME.TOP_PRODUCT]);
        },
      },
    ];
  }, [navigate]);
  const textById = useMemo(() => {
    return {
      [BUTTON_LIST[0].id]: "List Transaksi",
      [BUTTON_LIST[1].id]: "Produk Terlaris",
    };
  }, []);
  const dashboardQuery: DashboardBaseQuery = useMemo(
    () => ({
      startDate: dateRange.startDate.format("YYYY-MM-DD"),
      endDate: dateRange.endDate.format("YYYY-MM-DD"),
      companyID: companyId,
      sort: "desc",
    }),
    [dateRange]
  );

  const transactionQuery: TransactionBaseQuery = useMemo(
    () => ({
      startDate: today.format("YYYY-MM-DD"),
      endDate: today.format("YYYY-MM-DD"),
      companyID: companyId,
    }),
    [dateRange]
  );

  const upcomingSchedulesQuery: UpcomingScheduleQuery = useMemo(
    () => ({
      startDate: today.format("YYYY-MM-DD"),
      companyID: companyId,
      limit: 3,
      isUpcomingScheduleOnly: true,
    }),
    [dateRange]
  );
  const totalTransactionPerDateQuery =
    useGetTotalTransactionPerDate(dashboardQuery);
  const totalTransactionPerDate = useMemo(() => {
    return totalTransactionPerDateQuery.data?.data?.data;
  }, [totalTransactionPerDateQuery.data?.data?.data]);

  const totalTransactionByStatusQuery =
    useGetTransactionByStatus(dashboardQuery);
  const totalTransactionByStatus = useMemo(() => {
    return totalTransactionByStatusQuery.data?.data?.data;
  }, [totalTransactionByStatusQuery.data?.data?.data]);

  const productByTransactionQuery = useGetProductByTransaction(dashboardQuery);
  const productByTransaction = useMemo(() => {
    return productByTransactionQuery.data?.data?.data;
  }, [productByTransactionQuery.data?.data?.data]);

  const transactionOverviewQuery = useGetTransactionOverview(dashboardQuery);
  const transactionOverview = useMemo(() => {
    return transactionOverviewQuery.data?.data?.data;
  }, [transactionOverviewQuery.data?.data?.data]);

  const getUpcomingSchedulesQuery = useGetUpcomingSchedules(
    upcomingSchedulesQuery
  );
  const upcomingSchedules = useMemo(() => {
    return getUpcomingSchedulesQuery.data?.data?.data;
  }, [getUpcomingSchedulesQuery.data?.data?.data]);

  const dataset = () => {
    const dateData = [];
    for (
      let startDate = dayjs(dashboardQuery.startDate);
      startDate.isSame(dashboardQuery.endDate) ||
      startDate.isBefore(dashboardQuery.endDate);
      startDate = startDate.add(1, "days")
    ) {
      dateData.push({
        day: startDate.format("YYYY-MM-DD"),
        transactionCount: 0,
      });
    }
    return dateData;
  };

  const datasetPie = [
    { label: "Berhasil (0)", value: 0, color: COLOR.success500 },
    { label: "Gagal (0)", value: 0, color: COLOR.danger500 },
    { label: "Pending (0)", value: 0, color: COLOR.neutral500 },
  ];

  const _renderLoading = (size: number) =>
    Array.from({ length: size }).map((_, idx) => (
      <Skeleton key={idx} height="68px" sx={{ transform: "none" }} />
    ));

  const _renderProductList = () =>
    productByTransaction?.map((product) => (
      <Box
        key={product.productID}
        display="flex"
        flexDirection="row"
        border={`1px solid ${COLOR.neutral300}`}
        justifyContent="space-between"
        alignItems="center"
        borderRadius={2}
        p={1}
      >
        <Box display="flex" flexDirection="column">
          <Typography
            fontWeight={500}
            color={COLOR.neutral500}
            variant="caption"
          >
            ID: {product.productID}
          </Typography>
          <Typography fontWeight={600}>{product.productName}</Typography>
          <Typography
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            fontWeight={400}
          >
            {"Total Transaksi Berhasil:"}
            &nbsp;
            <Typography fontWeight={600}>{product.total}</Typography>
          </Typography>
        </Box>
      </Box>
    )) || [];

  return (
    <MobileContainer>
      <Box display="flex" flexDirection="column" height="100%" px={2} py={5}>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={1}
          >
            <Typography variant="body1" fontWeight={600}>
              Jadwal Akan Datang
            </Typography>
            <OpacityButton
              onClick={() => {
                navigate(RoutePath[ROUTE_NAME.EVENTS]);
              }}
            >
              <Typography variant="caption" color={COLOR.neutral500}>
                Lihat Semua
              </Typography>
            </OpacityButton>
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            {getUpcomingSchedulesQuery.isLoading ? (
              _renderLoading(3)
            ) : !upcomingSchedules?.length ? (
              <Typography>Belum ada jadwal</Typography>
            ) : (
              (upcomingSchedules || []).map((schedule) => {
                return (
                  <EventItem
                    status={
                      schedule.status === UpcomingScheduleStatus.ENDED
                        ? "done"
                        : schedule.status
                    }
                    date={dayjs(schedule.date)}
                    productName={schedule.productName}
                    startTime={schedule.startTime}
                    endTime={schedule.endTime}
                    timezone={TIMEZONE_SHORTLABEL[schedule.timezone]}
                    qty={schedule.qty}
                    customerName={schedule.fullName}
                    onClick={() => {
                      navigate(
                        `${RoutePath[ROUTE_NAME.TRANSACTION]}/${
                          schedule.transactionID
                        }`
                      );
                      // navigate to invoice
                    }}
                  />
                );
              })
            )}
          </Box>
        </Box>

        <Typography variant="body1" fontWeight={600} mt={2}>
          Menu Cepat
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          mt={2}
          gap={5}
        >
          {BUTTON_LIST.map((button) => (
            <MenuButton
              key={button.id}
              icon={<button.IconElement color={button.primaryColor} />}
              color={button.secondaryColor}
              text={textById[button.id]}
              onClick={button.onClick}
            />
          ))}
        </Box>

        {/*  {getTransactionListQueries.isLoading &&
            new Array(3).fill("").map((_, idx) => (
              <Box
                key={`skeleton-${idx}`}
                sx={{
                  border: `1px solid ${COLOR.neutral200}`,
                  borderRadius: "12px",
                  width: "100%",
                  pb: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    borderBottom: `1px solid ${COLOR.neutral200}`,
                    p: 1,
                    gap: 2,
                  }}
                >
                  <Skeleton width="30%" height="20px" />
                  <Skeleton width="40%" height="20px" />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  px={1}
                  gap={2}
                >
                  <Box
                    flex={1}
                    textAlign="left"
                    display="flex"
                    flexDirection="column"
                  >
                    <Skeleton width="130px" height="100%" />
                    <Skeleton width="100px" height="100%" />
                    <Skeleton width="180px" height="100%" />
                  </Box>
                </Box>
              </Box>
            ))}

          {!getTransactionListQueries.isLoading &&
            transactionList.length > 0 &&
            transactionList.slice(0, 3).map((transactionDetail) => {
              return (
                <TransactionCard
                  key={transactionDetail.transactionID}
                  onClick={() => {
                    navigate(
                      `${RoutePath[ROUTE_NAME.TRANSACTION]}/${
                        transactionDetail.transactionID
                      }`
                    );
                  }}
                  id={transactionDetail.transactionID}
                  createdAt={new Date(transactionDetail.createdAt)}
                  timezone={transactionDetail.timezone}
                  name={transactionDetail.productName}
                  qty={transactionDetail.totalItems}
                  status={transactionDetail.paymentStatus as PAYMENT_STATUS}
                  amount={transactionDetail.total}
                  currency={transactionDetail.currency as CurrencyCodeType}
                />
              );
            })}

          {!getTransactionListQueries.isLoading &&
            transactionList.length === 0 && (
              <Typography>Tidak ada Transaksi untuk saat ini.</Typography>
            )} */}
        {/* </Box> */}
        <Box my={3}>
          <Typography variant="body1" fontWeight={600} mb={1}>
            Analisa Bisnis
          </Typography>
          <Box my={1}>
            <DateRangePicker
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onChangeStartDate={(date) => {
                setDateRange((prev) => ({
                  ...prev,
                  startDate: date,
                }));
              }}
              onChangeEndDate={(date) => {
                setDateRange((prev) => ({
                  ...prev,
                  endDate: date,
                }));
              }}
            />
          </Box>
          <Box display="flex" flexDirection="row" gap={1}>
            {/* <Box p={1} border={`1px solid ${COLOR.neutral200}`} borderRadius={2} flex={1}>
              <Typography
                fontWeight={500}
                fontSize={12}
                color={COLOR.neutral500}
                display="flex"
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                gap={2}
              >
                Tingkat Konversi <FaExchangeAlt />
              </Typography>
              <Typography fontWeight={700} fontSize={16}>0%</Typography>
            </Box> */}
            <Box
              p={1}
              border={`1px solid ${COLOR.neutral200}`}
              borderRadius={2}
              flex={1}
            >
              <Typography
                fontWeight={500}
                fontSize={12}
                color={COLOR.neutral500}
                display="flex"
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                gap={2}
              >
                Total Penghasilan <FaWallet />
              </Typography>
              <Typography fontWeight={700} fontSize={16}>
                {formatCurrency(
                  transactionOverview?.totalTransactionAmount || 0,
                  companyDetail?.currency || "IDR"
                )}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          mt={3}
          py={1}
          width="100%"
          border={`1px solid ${COLOR.neutral200}`}
          borderRadius={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography my={2} fontWeight={600} textAlign="center">
            Jumlah Transaksi Berhasil
          </Typography>
          <BarChart
            sx={{
              width: "100%",
              ".MuiBarLabel-root": {
                fill: "white",
              },
            }}
            height={300}
            dataset={
              totalTransactionPerDateQuery.isLoading || !totalTransactionPerDate
                ? dataset()
                : totalTransactionPerDate.map((data) => ({
                    day: data.date,
                    transactionCount: data.total,
                  }))
            }
            xAxis={[{ scaleType: "band", dataKey: "day" }]}
            borderRadius={10}
            series={[
              {
                dataKey: "transactionCount",
                color: COLOR.primary500,
              },
            ]}
            barLabel="value"
          />
        </Box>

        <Box
          width="100%"
          mt={3}
          py={1}
          border={`1px solid ${COLOR.neutral200}`}
          borderRadius={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={2}
        >
          <Typography my={2} fontWeight={600} textAlign="center">
            Jumlah Transaksi Berdasarkan Status
          </Typography>
          <PieChart
            series={[
              {
                data: totalTransactionByStatus?.[0]
                  ? [
                      {
                        label: `Berhasil (${
                          totalTransactionByStatus[0]?.success || 0
                        })`,
                        value: totalTransactionByStatus[0]?.success || 0,
                        color: COLOR.success500,
                      },
                      {
                        label: `Pending (${
                          totalTransactionByStatus[0]?.pending || 0
                        })`,
                        value: totalTransactionByStatus[0]?.pending || 0,
                        color: COLOR.neutral500,
                      },
                      {
                        label: `Gagal (${
                          totalTransactionByStatus[0]?.failed || 0
                        })`,
                        value: totalTransactionByStatus[0]?.failed || 0,
                        color: COLOR.danger500,
                      },
                    ]
                  : datasetPie,
                highlightScope: { fade: "global", highlight: "item" },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: "gray",
                },
                innerRadius: 45,
              },
            ]}
            slotProps={{
              legend: {
                itemMarkHeight: 10,
                itemMarkWidth: 10,
                labelStyle: {
                  fontSize: 14,
                },
              },
            }}
            height={200}
          />
        </Box>
      </Box>
    </MobileContainer>
  );
};

export default Home;
