import { useMutation, UseMutationResult } from "react-query";
import {
  APICalculateTransactionPayload,
  APIChangeProductQuotaPayload,
  APICreateCompanyBodyPayload,
  APICreateCompanyBodyPayloadV2,
  APICreateNewTransactionPayload,
  APICreateProductBodyPayload,
  APIEditCompanyBodyPayload,
  APIEditCompanyBodyPayloadV2,
  APIGetTransactionQueries,
  APIRefundTransactionPayload,
  APIUpdateProductByIdPayload,
  APIUploadCompanyLogoPayload,
  ChangePasswordRequest,
  CreateProductLinkRequest,
  CreateProductReservationRequest,
  CreateProductSectionRequest,
  CreateWithdrawRequest,
  LoginGooglePayload,
  LoginPayload,
  LoginResponse,
  PRODUCT_TYPE,
  ProductDetailCompact,
  RegisterPayload,
  RegisterResponse,
  ResetPasswordRequest,
  UpdateCompanyV2Payload,
  UpdateProductLinkRequest,
  UpdateProductReservationRequest,
  UpdateProductSectionRequest,
  UploadStoragePayload,
  UpsertBankAccountRequest,
  VerifyBankAccountRequest,
  VerifyEmailRequest,
  VerifyWithdrawRequest,
} from "../api/request.types";
import {
  cancelTransaction,
  changePassword,
  changeProductQuota,
  changeProductQuotaV2,
  confirmTransaction,
  createCompanyAPI,
  createCompanyAPIV2,
  createNewTransaction,
  createProductAPI,
  createProductLinkV2,
  createProductReservationV2,
  createProductSectionV2,
  createWithdraw,
  deleteProductById,
  downloadTransactionCSV,
  getCalculateTransaction,
  getUserCompanies,
  loginAPI,
  loginGoogleAPI,
  logoutAPI,
  refundTransaction,
  registerAPI,
  requestForgotPassword,
  requestTokenAPI,
  resendBankAccount,
  resendVerificationEmail,
  resendWithdrawEmail,
  resetPassword,
  storageUpload,
  updateCompanyAPI,
  updateCompanyAPIV2,
  updateCompanyProductDetailAPI,
  updateProductById,
  updateProductLinkV2,
  updateProductReservationV2,
  updateProductSectionV2,
  uploadCompanyLogo,
  uploadProductPhotos,
  upsertBankAccount,
  verifyBankAccount,
  verifyEmail,
  verifyWithdraw,
} from "../api/request";
import { ErrorResponse } from "react-router-dom";
import { AxiosResponse } from "axios";

export type MutationResult<TData = any, TError = any> = UseMutationResult<
  AxiosResponse<TData>,
  TError,
  any
>;

export const useRegister = (): MutationResult<
  RegisterResponse,
  ErrorResponse
> => {
  return useMutation<
    AxiosResponse<RegisterResponse>,
    ErrorResponse,
    RegisterPayload
  >((data) => registerAPI(data), {
    onSuccess: (data) => {},
    onError: (error) => {},
  });
};
export const useLogin = (): MutationResult<LoginResponse, ErrorResponse> => {
  return useMutation<AxiosResponse<LoginResponse>, ErrorResponse, LoginPayload>(
    (data) => loginAPI(data),
    {
      onSuccess: (response) => {},
      onError: (error) => {},
    }
  );
};

export const useLoginGoogle = (): MutationResult<
  LoginResponse,
  ErrorResponse
> => {
  return useMutation<
    AxiosResponse<LoginResponse>,
    ErrorResponse,
    LoginGooglePayload
  >((data) => loginGoogleAPI(data), {
    onSuccess: (response) => {},
    onError: (error) => {},
  });
};
export const useRequestToken = () => {
  return useMutation(() => requestTokenAPI(), {
    onSuccess: (data) => {},
    onError: (error) => {},
  });
};

export const useLogout = () => {
  return useMutation(() => logoutAPI(), {
    onSuccess: () => {},
    onError: (error) => {},
  });
};

// export const useDownloadSignedUrl = (queryOpt?: Omit<UseMutationOptions<AxiosResponse<DownloadSignedUrlResponse, any>, unknown, string, unknown>, "mutationFn"> | undefined) => {
//   return useMutation((path: string) => downloadSignedUrl(path), {
//     ...queryOpt
//   });
// };

export const useCreateCompany = () => {
  return useMutation((data: APICreateCompanyBodyPayload) =>
    createCompanyAPI(data)
  );
};

export const useCreateCompanyV2 = () => {
  return useMutation((data: APICreateCompanyBodyPayloadV2) =>
    createCompanyAPIV2(data)
  );
};
export const useEditCompany = () => {
  return useMutation(
    (data: { companyId: string; companyEdit: APIEditCompanyBodyPayload }) =>
      updateCompanyAPI(data.companyId, data.companyEdit)
  );
};

export const useEditCompanyV2 = () => {
  return useMutation(
    (data: { companyId: string; companyEdit: APIEditCompanyBodyPayloadV2 }) =>
      updateCompanyAPIV2(data.companyId, data.companyEdit)
  );
};
export const useUploadBusinessImage = () => {
  return useMutation((data: APIUploadCompanyLogoPayload) =>
    uploadCompanyLogo(data)
  );
};
export const useCreateProduct = () => {
  return useMutation((data: APICreateProductBodyPayload) =>
    createProductAPI(data)
  );
};
export const useUploadProductPhotos = () => {
  return useMutation(
    (data: {
      files: Array<File | string>;
      companyId: string;
      productId: string;
    }) => uploadProductPhotos(data.files, data.companyId, data.productId)
  );
};

export const useCalculateTransaction = () => {
  return useMutation((data: APICalculateTransactionPayload) =>
    getCalculateTransaction(data)
  );
};

export const useCreateNewTransaction = () => {
  return useMutation((data: APICreateNewTransactionPayload) =>
    createNewTransaction(data)
  );
};

export const useChangeProductQuota = () => {
  return useMutation(
    (data: APIChangeProductQuotaPayload & { productId: string }) => {
      const { productId, ...payloadData } = data;
      return changeProductQuota(data.productId, payloadData);
    }
  );
};
export const useChangeProductQuotaV2 = () => {
  return useMutation(
    (data: APIChangeProductQuotaPayload & { productId: string }) => {
      const { productId, ...payloadData } = data;
      return changeProductQuotaV2(data.productId, payloadData);
    }
  );
};
export const useDeleteProductById = () => {
  return useMutation(
    ({ productId, type }: { productId: string; type?: PRODUCT_TYPE }) =>
      deleteProductById(productId, type)
  );
};

export const useCancelTransaction = () => {
  return useMutation((transactionId: string) =>
    cancelTransaction(transactionId)
  );
};
export const useConfirmTransaction = () => {
  return useMutation((transactionId: string) =>
    confirmTransaction(transactionId)
  );
};

export const useRefundTransaction = () => {
  return useMutation((data: APIRefundTransactionPayload) =>
    refundTransaction(data)
  );
};

export const useUpdateProductById = () => {
  return useMutation(
    ({
      productId,
      ...data
    }: APIUpdateProductByIdPayload & { productId: string }) =>
      updateProductById(productId, data)
  );
};

export const useUploadToStorageMutation = () => {
  return useMutation((data: UploadStoragePayload) => storageUpload(data));
};

export const useUpdateCompanyProductDetailV2 = () => {
  return useMutation(
    ({
      companyId,
      ...payload
    }: {
      companyId: string;
      details: ProductDetailCompact[];
    }) => updateCompanyProductDetailAPI(companyId, payload)
  );
};

export const useUpdateCompanyV2 = () => {
  return useMutation(
    ({
      companyId,
      ...payload
    }: UpdateCompanyV2Payload & { companyId: string }) =>
      updateCompanyAPI(companyId, payload)
  );
};

export const useCreateProductReservation = () => {
  return useMutation((data: CreateProductReservationRequest) =>
    createProductReservationV2(data)
  );
};

export const useCreateProductLink = () => {
  return useMutation((data: CreateProductLinkRequest) =>
    createProductLinkV2(data)
  );
};

export const useCreateProductSection = () => {
  return useMutation((data: CreateProductSectionRequest) =>
    createProductSectionV2(data)
  );
};

export const useUpdateProductReservationV2 = () => {
  return useMutation(
    ({
      productId,
      ...payload
    }: Partial<UpdateProductReservationRequest> & { productId: string }) =>
      updateProductReservationV2(payload, productId)
  );
};

export const useUpdateProductLinkV2 = () => {
  return useMutation(
    ({
      productId,
      ...payload
    }: Partial<UpdateProductLinkRequest> & { productId: string }) =>
      updateProductLinkV2(payload, productId)
  );
};

export const useUpdateProductSectionV2 = () => {
  return useMutation(
    ({
      productId,
      ...payload
    }: UpdateProductSectionRequest & { productId: string }) =>
      updateProductSectionV2(payload, productId)
  );
};

export const useGetCompanies = () => {
  return useMutation(() => getUserCompanies());
};

export const useRequestForgotPassword = () => {
  return useMutation(({ email }: { email: string }) =>
    requestForgotPassword(email)
  );
};

export const useChangePassword = () => {
  return useMutation((data: ChangePasswordRequest) => changePassword(data));
};

export const useResetPassword = () => {
  return useMutation((data: ResetPasswordRequest) => resetPassword(data));
};

export const useVerifyEmail = () => {
  return useMutation((data: VerifyEmailRequest) => verifyEmail(data));
};

export const useResendVerificationEmail = () => {
  return useMutation(({ email }: { email: string }) =>
    resendVerificationEmail(email)
  );
};

export const useDownloadCSVTransaction = () => {
  return useMutation(({ query }: { query: APIGetTransactionQueries }) =>
    downloadTransactionCSV(query)
  );
};

export const useUpsertBankAccount = () => {
  return useMutation(
    ({
      companyId,
      ...payload
    }: UpsertBankAccountRequest & { companyId: string }) =>
      upsertBankAccount(companyId, payload)
  );
};
export const useVerifyBankAccount = () => {
  return useMutation(
    ({
      companyId,
      ...payload
    }: VerifyBankAccountRequest & { companyId: string }) =>
      verifyBankAccount(companyId, payload)
  );
};
export const useResendBankAccount = () => {
  return useMutation(({ companyId, ...payload }: { companyId: string }) =>
    resendBankAccount(companyId)
  );
};

export const useCreateWithdraw = () => {
  return useMutation(
    ({
      companyId,
      ...payload
    }: CreateWithdrawRequest & { companyId: string }) =>
      createWithdraw(companyId, payload)
  );
};

export const useVerifyWithdraw = () => {
  return useMutation(
    ({
      companyId,
      ...payload
    }: VerifyWithdrawRequest & { companyId: string }) =>
      verifyWithdraw(companyId, payload)
  );
};

export const useResendWithdraw = () => {
  return useMutation(({ companyId }: { companyId: string }) =>
    resendWithdrawEmail(companyId)
  );
};
// export const useCreateProduct = () => {
//   const uploadPhotosMutation = useMutation((photos) =>
//     Promise.all(photos.map(uploadPhotoAPI))
//   );

//   const createProductMutation = useMutation((data) =>
//     createProductAPI(data)
//   );

//   const createProduct = async (businessData: Omit<APICreateProductBodyPayload, 'photos'>) => {
//     try {
//       const uploadedPhotoPath: Array<string> = await uploadPhotosMutation.mutateAsync(businessData.photos);

//       await createProductMutation.mutateAsync({
//         ...businessData,
//         photos: uploadedPhotoPath,
//       });
//     } catch (error) {
//       console.error('Error creating product:', error);
//     }
//   };

//   return { createProduct, uploadPhotosMutation, createProductMutation };
// };
