import { Theme } from "@emotion/react";
import { Box, SxProps } from "@mui/material";
import React from "react";

interface TicketSeparatorPropsI {
  circleProps?: {
    sx?: SxProps<Theme>;
  };
  lineProps?: {
    sx?: SxProps<Theme>;
  };
  hideCircle?: boolean;
  size?: number;
  circleBorderColor?: string;
  circleBorderWidth?: number;
  outerBorderColor?: string;
  outerBorderWidth?: number;
  paddingX?: number;
  separatorType?: "dashed" | "solid";
}
const TicketSeparator = (props: TicketSeparatorPropsI) => {
  const {
    hideCircle = true,
    size = 10,
    circleBorderColor = "white",
    circleBorderWidth = 1,
    outerBorderWidth = 0,
    outerBorderColor = "white",
    separatorType = "dashed",
    paddingX = 2,
  } = props;
  const toPx = (size: number | string) => `${size}px`;
  const circleSx: SxProps<Theme> = {
    content: '""',
    position: "absolute",
    height: toPx(size * 2),
    width: toPx(size),
    ...(props?.circleProps?.sx || {}),
  };
  const leftCircleSx: SxProps<Theme> = {
    ...circleSx,
    borderBottomRightRadius: toPx(size * 2),
    borderTopRightRadius: toPx(size * 2),
    left: `${size / 2 - outerBorderWidth}px`,
    transform: "translate(-50%, -50%)",
    borderTop: `${circleBorderWidth}px solid ${circleBorderColor}`,
    borderRight: `${circleBorderWidth}px solid ${circleBorderColor}`,
    borderBottom: `${circleBorderWidth}px solid ${circleBorderColor}`,
    borderLeft: `${outerBorderWidth}px solid ${outerBorderColor}`,
  };
  const rightCircleSx: SxProps<Theme> = {
    ...circleSx,
    borderBottomLeftRadius: toPx(size * 2),
    borderTopLeftRadius: toPx(size * 2),
    right: `${size / 2 - outerBorderWidth}px`,
    transform: "translate(50%, -50%)",
    borderTop: `${circleBorderWidth}px solid ${circleBorderColor}`,
    borderBottom: `${circleBorderWidth}px solid ${circleBorderColor}`,
    borderLeft: `${circleBorderWidth}px solid ${circleBorderColor}`,
    borderRight: `${outerBorderWidth}px solid ${outerBorderColor}`,
  };
  return (
    <Box
      sx={
        {
          position: "relative",
          my: 2,
          px: paddingX,
        } as SxProps<Theme>
      }
    >
      {!hideCircle && <Box sx={leftCircleSx} />}
      <Box
        sx={{
          borderBottom: `1px ${separatorType} #CBCBCB`,
          ...(props?.lineProps?.sx || {}),
        }}
      />
      {!hideCircle && <Box sx={rightCircleSx} />}
    </Box>
  );
};

export default TicketSeparator;
