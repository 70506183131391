import React from 'react';
import { IconSVGProps } from '../../types/globalTypes';

const WalletIcon: React.FC<IconSVGProps> = ({
  size = 18,
  color = "#F59E0B",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666748 3.83333C0.666748 1.99238 2.15913 0.5 4.00008 0.5H14.0001C15.841 0.5 17.3334 1.99238 17.3334 3.83333V5.08333H14.0001C12.3893 5.08333 11.0834 6.38917 11.0834 8C11.0834 9.61083 12.3893 10.9167 14.0001 10.9167H17.3334V12.1667C17.3334 14.0076 15.841 15.5 14.0001 15.5H4.00008C2.15913 15.5 0.666748 14.0076 0.666748 12.1667V3.83333ZM14.0001 9.66667H17.3334V6.33333H14.0001C13.0796 6.33333 12.3334 7.07953 12.3334 8C12.3334 8.92048 13.0796 9.66667 14.0001 9.66667Z"
        fill={color}
      />
    </svg>
  );
};

export default WalletIcon;
