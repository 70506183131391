import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { LuPencil } from "react-icons/lu";
import { COLOR } from "../../../utils/color";
import SubMenuHeader from "../../../components/Layout/SubMenuHeader/SubMenuHeader";
import {
  FaArrowLeft,
  FaDiscord,
  FaEtsy,
  FaFacebook,
  FaInstagram,
  FaLink,
  FaLinkedin,
  FaPinterest,
  FaSnapchat,
  FaTiktok,
  FaTwitch,
  FaTwitter,
  FaVimeo,
  FaYoutube,
} from "react-icons/fa";
import OpacityButton from "../../../components/OpacityButton/OpacityButton";
import { PiLinkSimple } from "react-icons/pi";
import {
  BusinessImageContainerStyle,
  BusinessImageEditStyle,
  ContainerCss,
  ContainerStyle,
  ImageButtonStyle,
} from "./EditBusiness.styles";
import {
  ElipsisText,
  FieldContainer,
  FormContainerStyle,
  TextButtonSx,
} from "../../../styles/global.styles";
import BoardIcon from "../../../assets/svg/BoardIcon";
import TextInput from "../../../components/Forms/TextInput/TextInput";
import TextArea from "../../../components/Forms/TextArea/TextArea";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import CustomButton from "../../../components/CustomButton/CustomButton";
import UserIcon from "../../../assets/svg/UserIcon";
import {
  DEFAULT_SNACKBAR_PROPS,
  NEEMA_LOGO_SIGNED_URL,
  NEEMA_URL,
  ROUTE_NAME,
  RoutePath,
  STORAGE_KEY,
  TEMPLATE_HEX_COLOR,
  TEMPLATE_PAGE,
  TIMEZONE_LABEL,
  TIMEZONES,
} from "../../../utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import LocationIcon from "../../../assets/svg/LocationIcon";
import useLocalStorage from "../../../hooks/useLocalStorage";
import HiddenInput from "../../../components/HiddenInput/HiddenInput";
import InputTitle from "../../../components/Forms/InputTitle/InputTitle";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../../redux/reducers/confirmationDialog";
import {
  base64ToFile,
  fileToBase64,
  isValidNumber,
  selectedLocationStr,
} from "../../../utils/helper";
import SingleSelect from "../../../components/Forms/SingleSelect/SingleSelect";
import { businessDataSchema } from "../../../joiSchema/business";
import Joi, { ValidationErrorItem } from "joi";
import { RootReducerState } from "../../../redux/reducers";
import {
  BusinessDataType,
  resetEditBusinessData,
  setEditBusinessData,
} from "../../../redux/reducers/business";
import ImageRenderer from "../../../components/ImageRenderer/ImageRenderer";
import { useSnackbar } from "notistack";
import { useGetCompanyById } from "../../../query/queries";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import MapIframe from "../../../components/MapIframe/MapIframe";
import { getEmbedMapSrc } from "../../../utils/maps";
import SearchIcon from "../../../assets/svg/SearchIcon";
import { requestGeolocationPermission } from "../../../utils/permission";
import { GEOLOCATION_PERMISSION } from "../../../types/permission";
import { errorLogger } from "../../../utils/logger";
import TargetIcon from "../../../assets/svg/TargetIcon";
import {
  BpCheckedIcon,
  BpIcon,
} from "../../../components/CheckboxIcon/CheckboxIcon";
import { useElementHeight } from "../../../hooks/useElementHeight";
import { LocationData } from "../LocationBusiness/LocationBusiness";
import {
  BusinessTemplateData,
  TemplateData,
} from "../PageTemplate/PageTemplate";
import {
  useEditCompany,
  useEditCompanyV2,
  useUploadBusinessImage,
} from "../../../query/mutations";
import { FooterContainer } from "../../ProductDetail/ProductDetail.styles";
import { PageTemplateType, SocialMediaType } from "../../../api/request.types";
import { MdOutlineMail } from "react-icons/md";
import NeemaLogoPNG from "../../../assets/images/neema-logo.png";
import { MAX_FILE_SIZE_IN_MB, compressImage } from "../../../utils/image";

const DEFAULT_ERROR_MESSAGE = {
  url: undefined,
  name: undefined,
  description: undefined,
  location: undefined,
  locationDetail: undefined,
  timezone: undefined,
  image: undefined,
  socialMedia: undefined,
};

export const DEFAULT_LOCATION_DATA: LocationData = {
  lat: null,
  long: null,
  search: "",
  lastChanged: "none",
  isCustomLocation: false,
  error: "",
};

export const DEFAULT_TEMPLATE_DATA: BusinessTemplateData = {
  color: TEMPLATE_HEX_COLOR[0].primary,
  templateId: "Type1",
};
const DEFAULT_USER_LOCATION = {
  lat: -612.3123,
  long: 123.1231,
};
export const SX_P_M_SIZE = 3;

export const DEFAULT_BUSINESS_DATA = {
  url: "",
  name: "",
  description: "",
  location: {
    lat: 0,
    long: 0,
    search: "",
    useManualLocation: false,
  },
  locationDetail: "",
  timezone: TIMEZONES[0],
  image: undefined,
  templateId: "",
  color: "",
  socialMedia: {
    tiktok: "",
    instagram: "",
    youtube: "",
    email: "",
    discord: "",
    twitter: "",
    twitch: "",
    facebook: "",
    snapchat: "",
    linkedin: "",
    pinterest: "",
    vimeo: "",
    etsy: "",
    link: "",
  },
};

const EditBusiness: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const footerRef = useRef<HTMLDivElement>(null);
  const footerHeight = useElementHeight(footerRef);

  const { businessId } = useParams<{ businessId: string }>();
  const getCompanyById = useGetCompanyById(businessId || "", {
    enabled: true,
  });

  // bottom sheet for location
  const bottomSheetRef = useRef<BottomSheetRef>(null);
  const [openBotSheet, setOpenBotSheet] = useState<boolean>(false);

  // bottom sheet for template view
  const bottomTemplateSheetRef = useRef<BottomSheetRef>(null);
  const [openBotTemplateSheet, setOpenBotTemplateSheet] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLogoChanged, setIsLogoChanged] = useState<boolean>(false);

  const [geolocationPermission, setGeolocationPermission] =
    useState<GEOLOCATION_PERMISSION>(GEOLOCATION_PERMISSION.INITIAL);

  const { editData: businessData } = useSelector(
    (state: RootReducerState) => state.businessReducer
  );

  const [errors, setErrors] = useLocalStorage<
    Record<
      keyof Omit<BusinessDataType, "templateId" | "color">,
      undefined | string
    >
  >(STORAGE_KEY.BUSINESS_DATA_ERROR, DEFAULT_ERROR_MESSAGE);

  const mappedLocationData: LocationData = {
    lat: businessData.location.lat.toString(),
    long: businessData.location.long.toString(),
    search: businessData.location.search,
    lastChanged:
      businessData.location.lat && businessData.location.lat
        ? "latlong"
        : "search",
    isCustomLocation: businessData.location.useManualLocation,
    error: "",
  };

  const [locationData, setLocationDataState] = useState<LocationData>(
    mappedLocationData || DEFAULT_LOCATION_DATA
  );
  const [savedLocation, setSavedLocation] = useState<LocationData>(
    mappedLocationData || DEFAULT_LOCATION_DATA
  );

  const mappedTemplateData: BusinessTemplateData = {
    color: businessData.color,
    templateId: businessData.templateId,
  };

  const [businessTemplateData, setBusinessTemplateData] =
    useState<BusinessTemplateData>(mappedTemplateData || DEFAULT_TEMPLATE_DATA);
  const existingTemplate = TEMPLATE_HEX_COLOR.find(
    (template) => template.primary === businessTemplateData.color
  );
  const [templateData, setTemplateData] = useLocalStorage<TemplateData>(
    STORAGE_KEY.TEMPLATE_DATA,
    { ...(existingTemplate || TEMPLATE_HEX_COLOR[0]) }
  );

  const updateCompanyMutation = useEditCompanyV2();
  const uploadBusinessImageMutation = useUploadBusinessImage();

  const handleUpdateCompany = async () => {
    await updateCompanyMutation.mutateAsync({
      companyId: businessId || "",
      companyEdit: {
        name: businessData.name,
        disabled: false,
        description: businessData.description,
        currency: "IDR",
        location: {
          mapLocation: businessData.location.search,
          completeAddress: businessData.locationDetail,
          useManualLocation: businessData.location.useManualLocation,
          longitude: businessData.location.lat,
          latitude: businessData.location.long,
          timezone: businessData.timezone,
        },
        companyDomainName: businessData.url,
        logoPath: "",
        template: businessData.templateId,
        color: businessData.color,
        socialMedia: businessData.socialMedia,
      },
    });
    if (isLogoChanged) {
      const imageFile = base64ToFile(
        businessData.image as string,
        "profile-image"
      );

      if (imageFile.size > MAX_FILE_SIZE_IN_MB) {
        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: "Maximum File Size is 5MB",
        });
        return "";
      }

      const compressedImage = await compressImage(imageFile);

      await uploadBusinessImageMutation.mutateAsync({
        companyId: businessId || "",
        file: compressedImage,
      });
    }
    dispatch(resetEditBusinessData());
    navigate(RoutePath[ROUTE_NAME.BUSINESS], { replace: true });
  };
  const saveLocation = () => {
    const mappedLocationData = {
      lat: locationData.lat ? Number(locationData.lat) : 0,
      long: locationData.long ? Number(locationData.long) : 0,
      search: locationData.search,
      useManualLocation: locationData.isCustomLocation,
    };
    handleOnLocationChange(mappedLocationData);
    setSavedLocation(locationData);
  };
  const saveTemplate = () => {
    dispatch(
      setEditBusinessData({
        templateId: businessTemplateData.templateId,
        color: businessTemplateData.color,
      })
    );
    setOpenBotTemplateSheet(false);
  };

  useEffect(() => {}, [savedLocation]);

  const { enqueueSnackbar } = useSnackbar();

  const fileRef = useRef<HTMLInputElement | null>(null);
  const [imagePreview, setImagePreview] = useLocalStorage<string | undefined>(
    STORAGE_KEY.CREATE_BUSINESS_IMAGE_PREVIEW,
    ""
  );

  const clearAllState = () => {
    dispatch(resetEditBusinessData());
    setErrors(DEFAULT_ERROR_MESSAGE);
    setImagePreview("");
  };
  const handleOnChange =
    (key: keyof BusinessDataType) =>
    async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (
        key === "image" &&
        e.target instanceof HTMLInputElement &&
        e.target.files
      ) {
        const file = e.target.files[0];
        if (file) {
          try {
            const base64Image = await fileToBase64(file);
            dispatch(
              setEditBusinessData({
                [key]: base64Image,
              })
            );
            setIsLogoChanged(true);
          } catch (error) {
            enqueueSnackbar("Error Preview Image", {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              autoHideDuration: 5000,
            });
          }
        }
      } else {
        dispatch(
          setEditBusinessData({
            [key]: e.target.value,
          })
        );
      }
    };

  const handleOnChangeSocialMedia =
    (key: keyof SocialMediaType) =>
    async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newSocialMedia: BusinessDataType["socialMedia"] = {
        ...businessData.socialMedia,
        [key]: e.target.value,
      };

      dispatch(
        setEditBusinessData({
          socialMedia: newSocialMedia,
        })
      );
    };

  const handleChangeLocationData = useCallback(
    (
      data: Partial<Omit<LocationData, "lastChanged">>,
      lastChanged?: LocationData["lastChanged"]
    ) => {
      setLocationDataState((prev) => ({
        ...prev,
        ...data,
        ...(lastChanged ? { lastChanged } : {}),
      }));
    },
    [setLocationDataState]
  );

  const handleOnLocationChange = (
    location: Partial<BusinessDataType["location"]>
  ) => {
    const newLocation: BusinessDataType["location"] = {
      ...businessData.location,
      ...location,
    };
    dispatch(
      setEditBusinessData({
        location: newLocation,
      })
    );
  };

  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  useEffect(() => {
    const companyDetail = getCompanyById.data?.data.data;
    const mappedAPItoRedux: Partial<BusinessDataType> = {
      ...companyDetail,
      url: companyDetail?.companyDomainName || "",
      locationDetail: companyDetail?.location.completeAddress || "",
      timezone: companyDetail?.location.timezone || "",
      image: companyDetail?.logoSignedURL || "",
      templateId: (companyDetail?.template || "Type1") as PageTemplateType,
      location: {
        lat: companyDetail?.location.latitude || 0,
        long: companyDetail?.location.longitude || 0,
        search: companyDetail?.location.mapLocation || "",
        useManualLocation: companyDetail?.location.useManualLocation || false,
      },
    };
    dispatch(setEditBusinessData(mappedAPItoRedux));
  }, [getCompanyById.isFetched]);

  const previousLocationDataRef = useRef(businessData.location);
  // useEffect(() => {
  //   const dataToBeSaved =
  //     savedLocation.lastChanged === "latlong"
  //       ? {
  //           lat: Number(savedLocation.lat),
  //           long: Number(savedLocation.long),
  //           search: "",
  //           useManualLocation: savedLocation.isCustomLocation,
  //         }
  //       : {
  //           search: savedLocation.search,
  //           lat: 0,
  //           long: 0,
  //           useManualLocation: savedLocation.isCustomLocation,
  //         };
  //   const isChanged =
  //     JSON.stringify(dataToBeSaved) !==
  //     JSON.stringify(previousLocationDataRef.current);
  //   if (isChanged) {
  //     dispatch(
  //       setBusinessData({
  //         location: {
  //           ...dataToBeSaved,
  //         },
  //       })
  //     );
  //     previousLocationDataRef.current = { ...dataToBeSaved };
  //   }
  // }, [dispatch, savedLocation]);

  const validateError = () => {
    const { error } = businessDataSchema.validate(businessData, {
      abortEarly: false,
    });
    if (error) {
      const errors = error.details.reduce(
        (acc: any, err: ValidationErrorItem) => {
          return { ...acc, [err.path[0]]: err.message };
        },
        {}
      );
      setErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      return true;
    }
    return false;
  };
  const handleBlur = (field: keyof BusinessDataType) => {
    const { error } = Joi.object({
      [field]: businessDataSchema.extract(field),
    }).validate({ [field]: businessData[field] });
    if (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error.message,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };

  const handleOnPressContinue = () => {
    const hasError = validateError();
    if (!hasError) {
      const existingTemplate =
        TEMPLATE_HEX_COLOR.find(
          (template) => template.primary === businessData.color
        ) || TEMPLATE_HEX_COLOR[0];

      setTemplateData(existingTemplate || TEMPLATE_HEX_COLOR[0]);
      setBusinessTemplateData({
        color: existingTemplate.primary,
        templateId: businessData.templateId as PageTemplateType,
      });
      setOpenBotTemplateSheet(true);
    }
  };

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.FormEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    handleOnPressContinue();
  };

  const getGeoLocation = async () => {
    try {
      setIsLoading(true);
      const { status, position } = await requestGeolocationPermission();
      setGeolocationPermission(status);
      const lat = position?.latitude ?? 0;
      const long = position?.longitude ?? 0;
      if (
        status === GEOLOCATION_PERMISSION.GRANTED &&
        (lat !== 0 || long !== 0)
      ) {
        handleChangeLocationData(
          {
            search: "",
            lat: lat.toString(),
            long: long.toString(),
          },
          "latlong"
        );
      }
    } catch (error) {
      errorLogger(error);
    } finally {
      setIsLoading(false);
    }
  };

  const _resetValue = useCallback(() => {
    handleChangeLocationData(DEFAULT_LOCATION_DATA, "none");
  }, [handleChangeLocationData]);

  useEffect(() => {
    _resetValue();
  }, [_resetValue]);

  const _textByLocationPermission = (permission: GEOLOCATION_PERMISSION) => {
    switch (permission) {
      case GEOLOCATION_PERMISSION.GRANTED:
        return [locationData.lat, locationData.long].join(", ");
      case GEOLOCATION_PERMISSION.INITIAL:
        return "Tekan untuk mendapatkan lokasi saat ini";
      case GEOLOCATION_PERMISSION.DENIED:
      default:
        return "Mohon berikan akses ke lokasi";
    }
  };

  const selectedLocation = selectedLocationStr({
    search: businessData.location.search,
    lat: businessData.location.lat,
    long: businessData.location.long,
  });
  const timezoneOptions = TIMEZONES.map((tz) => ({
    label: TIMEZONE_LABEL[tz] || "unknown",
    value: tz,
  }));
  return (
    <AppContainer
      bottomChildren={
        <Box sx={FooterContainer}>
          <CustomButton fullWidth onClick={handleUpdateCompany}>
            <Typography variant="body2" fontWeight={500}>
              Simpan
            </Typography>
          </CustomButton>
        </Box>
      }
    >
      <Box sx={ContainerStyle}>
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
            onClick: () => {
              dispatch(
                openDialog({
                  title: "Konfirmasi",
                  message:
                    "Anda yakin ingin kembali? anda harus mengulang perubahan data anda",
                  secondaryBtn: {
                    onClick: () => {
                      clearAllState();
                      navigate(-1);
                    },
                  },
                })
              );
            },
          }}
          text={"Ubah Data Usaha"}
        />
        <OpacityButton
          sx={ImageButtonStyle}
          onClick={() => {
            if (fileRef.current) {
              fileRef.current.click();
            }
          }}
        >
          <HiddenInput
            ref={fileRef}
            onChange={handleOnChange("image")}
            hideMethod="none"
          />
          <Box sx={BusinessImageContainerStyle}>
            <ImageRenderer
              className="img-default"
              style={{ objectFit: "cover" }}
              alt="business-logo"
              src={businessData.image || NEEMA_LOGO_SIGNED_URL}
            />
          </Box>
          <Box sx={BusinessImageEditStyle}>
            <LuPencil size={16} color="white" />
          </Box>
        </OpacityButton>
        <form style={ContainerCss} onSubmit={handleSubmit}>
          <Box sx={FormContainerStyle}>
            <Box sx={FieldContainer}>
              <TextInput
                title="URL Usaha"
                required={true}
                disabled
                textInputProps={{
                  placeholder: "bisnisku",
                  value: businessData.url,
                  style: { textTransform: "lowercase" },
                  onChange: handleOnChange("url"),
                  onBlur: () => handleBlur("url"),
                }}
                startEndorment={
                  <Box
                    component="span"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flexStart"
                  >
                    <PiLinkSimple size={20} color={COLOR.neutral500} />
                    <Typography color={COLOR.neutral500} ml={1}>
                      {NEEMA_URL}/
                    </Typography>
                  </Box>
                }
                error={errors.url !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.url,
                }}
              />
            </Box>

            <Box sx={FieldContainer}>
              <TextInput
                title="Nama Usaha"
                required
                startEndorment={
                  <BoardIcon size={20} color={COLOR.neutral500} />
                }
                textInputProps={{
                  placeholder: "Bisnisku",
                  value: businessData.name,
                  onChange: handleOnChange("name"),
                  onBlur: () => handleBlur("name"),
                }}
                error={errors.name !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.name,
                }}
              />
            </Box>

            <Box sx={FieldContainer}>
              <TextArea
                title="Deskripsi Singkat"
                required
                textAreaProps={{
                  value: businessData.description,
                  onChange: handleOnChange("description"),
                  placeholder:
                    "Deskripsikan bisnis anda yang anda ingin perlihatkan di website anda.",
                  onBlur: () => handleBlur("description"),
                }}
                error={errors.description !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.description,
                }}
              />
            </Box>
            <Box sx={FieldContainer}>
              <SingleSelect
                title="Zona Waktu"
                required
                error={errors.timezone !== undefined}
                helper={{
                  text: errors.timezone,
                }}
                disabled
                optionProps={{
                  value: businessData.timezone,
                  onChange: (selectedOption?: string) => {
                    dispatch(
                      setEditBusinessData({
                        timezone: selectedOption || "",
                      })
                    );
                  },
                  options: timezoneOptions,
                  defaultValue: timezoneOptions[0].value,
                  placeholder: "Pilih Zona Waktu",
                }}
              />
            </Box>
            {/* <Box sx={FieldContainer}>
              <InputTitle title="Pilih Lokasi" />
              <CustomButton
                variant="outlined"
                sx={TextButtonSx}
                endormentSx={{
                  start: { mr: 1 },
                }}
                startEndorment={<LocationIcon />}
                onClick={() => {
                  const mappedLocationData: LocationData = {
                    lat: businessData.location.lat.toString(),
                    long: businessData.location.long.toString(),
                    search: businessData.location.search,
                    lastChanged:
                      businessData.location.lat && businessData.location.lat
                        ? "latlong"
                        : "search",
                    isCustomLocation: businessData.location.useManualLocation,
                    error: "",
                  };
                  setLocationDataState(mappedLocationData);
                  setSavedLocation(mappedLocationData);
                  setOpenBotSheet(true);
                }}
              >
                <Typography
                  component="span"
                  fontWeight="inherit"
                  fontSize="inherit"
                  color={selectedLocation ? "black" : COLOR.neutral500}
                >
                  {selectedLocation || "Belum Pilih Lokasi"}
                </Typography>
              </CustomButton>
            </Box> */}
            <Box sx={FieldContainer}>
              <TextArea
                title="Alamat Lengkap"
                required={false}
                textAreaProps={{
                  value: businessData.locationDetail,
                  onChange: handleOnChange("locationDetail"),
                  placeholder: "Tulis alamat lengkap disini",
                }}
                error={errors.locationDetail !== undefined}
                helper={{
                  text: errors.locationDetail,
                }}
              />
            </Box>
            <Box sx={FieldContainer}>
              <InputTitle
                title="Social Media"
                required={false}
                textProps={{
                  sx: { mb: 1 },
                }}
              />
              <TextInput
                title="Tiktok"
                startEndorment={<FaTiktok size={20} color={COLOR.neutral500} />}
                textInputProps={{
                  placeholder: "https://www.tiktok.com/",
                  value: businessData.socialMedia?.tiktok,
                  onChange: handleOnChangeSocialMedia("tiktok"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Instagram"
                startEndorment={
                  <FaInstagram size={20} color={COLOR.neutral500} />
                }
                textInputProps={{
                  placeholder: "https://www.instagram.com/",
                  value: businessData.socialMedia?.instagram || "",
                  onChange: handleOnChangeSocialMedia("instagram"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Youtube"
                startEndorment={
                  <FaYoutube size={20} color={COLOR.neutral500} />
                }
                textInputProps={{
                  placeholder: "https://www.youtube.com/",
                  value: businessData.socialMedia?.youtube || "",
                  onChange: handleOnChangeSocialMedia("youtube"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Email"
                startEndorment={
                  <MdOutlineMail size={20} color={COLOR.neutral500} />
                }
                textInputProps={{
                  placeholder: "mailto:mail@gmail.com",
                  value: businessData.socialMedia?.email || "",
                  onChange: handleOnChangeSocialMedia("email"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Discord"
                startEndorment={
                  <FaDiscord size={20} color={COLOR.neutral500} />
                }
                textInputProps={{
                  placeholder: "https://discord.com/",
                  value: businessData.socialMedia?.discord || "",
                  onChange: handleOnChangeSocialMedia("discord"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Twitter"
                startEndorment={
                  <FaTwitter size={20} color={COLOR.neutral500} />
                }
                textInputProps={{
                  placeholder: "https://twitter.com/",
                  value: businessData.socialMedia?.twitter || "",
                  onChange: handleOnChangeSocialMedia("twitter"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Twitch"
                startEndorment={<FaTwitch size={20} color={COLOR.neutral500} />}
                textInputProps={{
                  placeholder: "https://www.twitch.tv/",
                  value: businessData.socialMedia?.twitch,
                  onChange: handleOnChangeSocialMedia("twitch"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Facebook"
                startEndorment={
                  <FaFacebook size={20} color={COLOR.neutral500} />
                }
                textInputProps={{
                  placeholder: "https://www.facebook.com/",
                  value: businessData.socialMedia?.facebook,
                  onChange: handleOnChangeSocialMedia("facebook"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Snapchat"
                startEndorment={
                  <FaSnapchat size={20} color={COLOR.neutral500} />
                }
                textInputProps={{
                  placeholder: "https://www.snapchat.com/",
                  value: businessData.socialMedia?.snapchat,
                  onChange: handleOnChangeSocialMedia("snapchat"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Linkedin"
                startEndorment={
                  <FaLinkedin size={20} color={COLOR.neutral500} />
                }
                textInputProps={{
                  placeholder: "https://www.linkedin.com/",
                  value: businessData.socialMedia?.linkedin,
                  onChange: handleOnChangeSocialMedia("linkedin"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Pinterest"
                startEndorment={
                  <FaPinterest size={20} color={COLOR.neutral500} />
                }
                textInputProps={{
                  placeholder: "https://www.pinterest.com/",
                  value: businessData.socialMedia?.pinterest,
                  onChange: handleOnChangeSocialMedia("pinterest"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Vimeo"
                startEndorment={<FaVimeo size={20} color={COLOR.neutral500} />}
                textInputProps={{
                  placeholder: "https://vimeo.com/",
                  value: businessData.socialMedia?.vimeo,
                  onChange: handleOnChangeSocialMedia("vimeo"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Etsy"
                startEndorment={<FaEtsy size={20} color={COLOR.neutral500} />}
                textInputProps={{
                  placeholder: "https://www.etsy.com/",
                  value: businessData.socialMedia?.etsy,
                  onChange: handleOnChangeSocialMedia("etsy"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
              <TextInput
                title="Link"
                startEndorment={<FaLink size={20} color={COLOR.neutral500} />}
                textInputProps={{
                  placeholder: "https://link.com/",
                  value: businessData.socialMedia?.link,
                  onChange: handleOnChangeSocialMedia("link"),
                  onBlur: () => handleBlur("socialMedia"),
                }}
                error={errors.socialMedia !== undefined}
                helper={{
                  color: COLOR.danger500,
                  text: errors.socialMedia,
                }}
                sx={{ mb: 1 }}
              />
            </Box>
          </Box>
          <HiddenInput
            type="submit"
            accept={"*"}
            hideMethod="none"
            onSubmit={handleSubmit}
          />
        </form>
        <BottomSheet
          ref={bottomSheetRef}
          open={openBotSheet}
          onDismiss={() => setOpenBotSheet(false)}
          style={{
            height: "100vh", // Full viewport height
            maxHeight: "100vh", // Ensure it doesn’t exceed viewport height
            top: 0, // Align it to the top of the viewport
            bottom: 0, // Align it to the bottom of the viewport
          }}
          snapPoints={({ height, minHeight, maxHeight }) => {
            return [minHeight * 1.25, maxHeight * 1.25];
          }}
        >
          <SubMenuHeader
            leftNav={{
              icon: "",
            }}
            text={"Pilih Lokasi"}
          />
          <Box height={"100%"}>
            <MapIframe
              src={getEmbedMapSrc(
                locationData.lastChanged === "latlong"
                  ? [locationData.lat, locationData.long].join(",")
                  : locationData.search
              )}
              style={{ width: "100%", height: "70%", pointerEvents: "none" }}
            />
            <Box sx={{ px: 2, mt: 1, position: "relative" }}>
              <TextInput
                startEndorment={<SearchIcon />}
                textInputProps={{
                  placeholder: "Cari lokasi mu",
                  value: locationData.search,
                  onChange: (e) =>
                    handleChangeLocationData(
                      {
                        search: e.target.value,
                      },
                      "search"
                    ),
                }}
                disabled={isLoading}
              />
              <CustomButton
                fullWidth
                startEndorment={<TargetIcon />}
                disabled={isLoading}
                sx={{ mt: 2, px: 3 }}
                onClick={() => {
                  getGeoLocation();
                }}
              >
                <Box sx={{ width: "100%", textAlign: "left" }}>
                  <Typography fontWeight={600}>
                    Gunakan Lokasi anda saat ini
                  </Typography>
                  <Typography
                    fontWeight={400}
                    variant="caption"
                    style={ElipsisText(1)}
                  >
                    {_textByLocationPermission(geolocationPermission)}
                  </Typography>
                </Box>
              </CustomButton>

              <CustomButton
                fullWidth
                startEndorment={<LocationIcon />}
                disabled={isLoading}
                sx={{
                  mt: 2,
                  px: 3,
                  borderColor: COLOR.neutral300,
                  backgroundColor: COLOR.neutral50,
                  color: "black",
                  "&:hover": {
                    filter: "brightness(98%)",
                  },
                  "&:active": {
                    filter: "brightness(95%)",
                  },
                }}
                onClick={() => {
                  handleChangeLocationData(
                    {
                      search: "",
                      lat: DEFAULT_USER_LOCATION.lat.toString(),
                      long: DEFAULT_USER_LOCATION.long.toString(),
                    },
                    "latlong"
                  );
                  setGeolocationPermission(GEOLOCATION_PERMISSION.INITIAL);
                }}
              >
                <Box sx={{ width: "100%", textAlign: "left" }}>
                  <Typography fontWeight={600}>Gunakan Lokasi Usaha</Typography>
                  <Typography color={COLOR.neutral500} variant="caption">
                    {Object.values(DEFAULT_USER_LOCATION).join(", ")}
                  </Typography>
                </Box>
              </CustomButton>
              <OpacityButton
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  py: 2,
                }}
                disabled={isLoading}
                disableOpacity={true}
                onClick={() => {
                  const isCustom = !locationData.isCustomLocation;
                  handleChangeLocationData({
                    isCustomLocation: isCustom,
                    ...(!isCustom
                      ? {
                          lat: null,
                          long: null,
                        }
                      : {}),
                  });
                }}
              >
                <Checkbox
                  inputProps={{
                    "aria-label": "Custom Location",
                  }}
                  checked={locationData.isCustomLocation}
                  disabled={isLoading}
                  disableRipple
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  sx={{
                    p: 0,
                    mr: 1,
                    pointerEvents: "none",
                  }}
                />
                <Typography>Atur lokasi secara manual</Typography>
              </OpacityButton>
              {locationData.isCustomLocation && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                    pb: 4,
                    marginBottom: `${footerHeight}px`,
                  }}
                >
                  <TextInput
                    title="Lat"
                    required
                    disabled={isLoading}
                    sx={{ flex: 1 }}
                    textInputProps={{
                      value: locationData.lat ?? "",
                      onChange: (e) =>
                        handleChangeLocationData({
                          lat: e.target.value,
                        }),
                    }}
                    error={!isValidNumber(locationData.lat ?? "")}
                  />
                  <TextInput
                    title="Long"
                    required
                    sx={{ flex: 1 }}
                    textInputProps={{
                      value: locationData.long ?? "",
                      onChange: (e) =>
                        handleChangeLocationData({
                          long: e.target.value,
                        }),
                    }}
                    error={!isValidNumber(locationData.long ?? "")}
                  />
                </Box>
              )}
            </Box>
            <Box
              ref={footerRef}
              sx={{
                ...FooterContainer,
                position: "absolute",
                bottom: 0,
                backgroundColor: "white",
              }}
            >
              <CustomButton
                fullWidth
                disabled={
                  locationData.lastChanged === "none" ||
                  !isValidNumber(locationData.lat ?? "") ||
                  !isValidNumber(locationData.long ?? "") ||
                  isLoading
                }
                onClick={() => {
                  saveLocation();
                  setOpenBotSheet(false);
                }}
              >
                <Typography variant="body1" fontWeight={500}>
                  simpan
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </BottomSheet>
        <BottomSheet
          ref={bottomTemplateSheetRef}
          open={openBotTemplateSheet}
          onDismiss={() => setOpenBotTemplateSheet(false)}
        >
          <SubMenuHeader
            leftNav={{
              icon: "",
            }}
            text={"Tampilan Usaha"}
          />

          <Box sx={{ mt: SX_P_M_SIZE }}>
            <Typography
              variant="h6"
              fontWeight={600}
              sx={{ mx: SX_P_M_SIZE, mb: 2 }}
            >
              Pilih Tema Warna
            </Typography>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                overflowY: "scroll",
                pb: SX_P_M_SIZE,
                "&::-webkit-scrollbar": {
                  width: 0,
                  height: 2,
                },
              }}
            >
              {TEMPLATE_HEX_COLOR.map((colorPalete, index) => {
                const isSelected =
                  colorPalete.primary === templateData.primary &&
                  colorPalete.secondary === templateData.secondary;
                return (
                  <OpacityButton
                    disableOpacity
                    disabled={isLoading}
                    onClick={() => {
                      setBusinessTemplateData({
                        ...businessTemplateData,
                        color: colorPalete.primary,
                      });

                      setTemplateData(colorPalete);
                    }}
                    key={index}
                    sx={{
                      width: "48px",
                      height: "48px",
                      backgroundColor: colorPalete.primary,
                      borderWidth: isSelected ? "4px" : 0,
                      borderStyle: "solid",
                      borderColor: colorPalete.secondary,
                      ml: index === 0 ? SX_P_M_SIZE : 1,
                      mr:
                        index === TEMPLATE_HEX_COLOR.length - 1
                          ? SX_P_M_SIZE
                          : 1,
                      borderRadius: "50%",
                      flex: "0 0 auto",
                      "&:hover": {
                        opacity: 0.8,
                        cursor: "pointer",
                      },
                    }}
                  />
                );
              })}
            </Box>
          </Box>

          {/* <Box
            sx={{
              px: SX_P_M_SIZE,
              mt: SX_P_M_SIZE,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography variant="h6" fontWeight={600} mb={2}>
              Template Usaha
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  md: `repeat(3, 1fr)`,
                  xs: `repeat(2, 1fr)`,
                },
                gap: 2,
                columnGap: 4,
                width: "100%",
                height: "100%",
              }}
            >
              {TEMPLATE_PAGE.map((item, index) => {
                const isSelected = item.id === businessTemplateData.templateId;
                return (
                  <OpacityButton
                    disableOpacity
                    key={item.id}
                    onClick={() => {
                      setBusinessTemplateData({
                        ...businessTemplateData,
                        templateId: item.id,
                      });
                    }}
                  >
                    <Box
                      key={index}
                      sx={{
                        aspectRatio: "5/6",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: `4px solid ${isSelected ? COLOR.primary200 : COLOR.neutral200
                          }`,
                        borderRadius: 2,
                        overflow: "hidden",
                        pointerEvents: isLoading ? "none" : "initial",
                      }}
                    >
                      <img
                        src="https://placehold.co/500x600/dbeafe/000"
                        className="img-default"
                        alt={item.id}
                        style={{ objectFit: "cover" }}
                      />
                    </Box>
                    <Typography
                      variant="caption"
                      textAlign="left"
                      color={COLOR.neutral500}
                      textTransform="capitalize"
                    >
                      {item.id}
                    </Typography>
                  </OpacityButton>
                );
              })}
            </Box>
          </Box> */}
          <Box
            sx={{
              borderTop: `1px solid ${COLOR.neutral200}`,
              minHeight: 80,
              width: "100%",
              px: 2,
              py: 1,
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <CustomButton
              fullWidth
              disabled={isLoading}
              onClick={() => saveTemplate()}
            >
              <Typography variant="body1" fontWeight={500}>
                Terapkan
              </Typography>
            </CustomButton>
          </Box>
        </BottomSheet>
      </Box>
    </AppContainer>
  );
};

export default EditBusiness;
