import { Box, Typography } from "@mui/material";
import OpacityButton, {
  OpacityButtonPropsI,
} from "../OpacityButton/OpacityButton";
import React from "react";

interface MenuButtonPropsI extends OpacityButtonPropsI {
  icon: React.ReactNode;
  color: string;
  text: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
const MenuButton = (props: MenuButtonPropsI) => {
  return (
    <OpacityButton
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      onClick={props.onClick}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          backgroundColor: props.color,
          mb: 1,
        }}
      >
        {props.icon}
      </Box>
      <Typography fontWeight={500} fontSize={12}>
        {props.text}
      </Typography>
    </OpacityButton>
  );
};

export default MenuButton;
