import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PublicCompanyWebsiteResponse } from "../../api/request.types";

export type PublicReducerState = {
  transaction: {
    snapToken?: string;
    transactionId?: string;
    timestamp: number;
  };
  domainData: PublicCompanyWebsiteResponse;
};

export const initialState: PublicReducerState = {
  transaction: {
    snapToken: undefined,
    transactionId: undefined,
    timestamp: -1,
  },
  domainData: {
    companyWebsiteID: "",
    companyID: "",
    companyDomainName: "",
    logoPath: "",
    logoSignedURL: "",
    template: "Type1",
    color: "",
    name: "",
    description: "",
    products: [],
    openingHours: "",
  },
};

const publicSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setPublicTransaction: (
      state,
      action: PayloadAction<PublicReducerState["transaction"]>
    ) => {
      return {
        ...state,
        transaction: {
          ...state.transaction,
          ...action.payload,
        },
      };
    },
    resetPublicTransaction: (state) => {
      return {
        ...state,
        transaction: {
          ...initialState.transaction,
        },
      };
    },
    setDomainData: (
      state,
      action: PayloadAction<PublicReducerState["domainData"]>
    ) => {
      return {
        ...state,
        domainData: {
          ...state.domainData,
          ...action.payload,
        },
      };
    },
  },
});

export const { setPublicTransaction, resetPublicTransaction, setDomainData } =
  publicSlice.actions;
export default publicSlice.reducer;
