import React, { useMemo, useEffect, useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../redux/reducers";
import { COLOR } from "../../utils/color";
import TextInput from "../../components/Forms/TextInput/TextInput";
import { useNavigate } from "react-router-dom";
import { ContainerStyle, FieldContainer } from "../../styles/global.styles";
import AppContainer from "../../components/Layout/AppContainer/AppContainer";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
// import { useGetWithdrawList, useWithdrawFunds } from "../../query/queries";
import CustomButton from "../../components/CustomButton/CustomButton";
import { enqueueSnackbar } from "notistack";
import {
  useGetCompanyAccountByID,
  useGetWithdrawList,
} from "../../query/queries";
import { formatCurrency } from "../../utils/helper";
import { useCreateWithdraw } from "../../query/mutations";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
  TIMEZONE_SHORTLABEL,
  TIMEZONES,
} from "../../utils/constant";
import { openDialog } from "../../redux/reducers/confirmationDialog";
import { setWithdrawData } from "../../redux/reducers/withdraw";
import { isAxiosError } from "axios";
import {
  DisbursementStatus,
  disbursementStatusColorMap,
  disbursementStatusMap,
} from "../../types/globalTypes";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

const Withdraw = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );

  const { withdrawData } = useSelector(
    (state: RootReducerState) => state.withdrawReducer
  );

  const companyId = userReducer.data.companyId;
  const companyAccountQuery = useGetCompanyAccountByID(companyId || "");
  const companyAccount = useMemo(() => {
    return companyAccountQuery.data?.data?.data;
  }, [companyAccountQuery.data?.data?.data]);

  const createWithdrawMutation = useCreateWithdraw();

  const GetWithdrawListQuery = useGetWithdrawList(companyId || "");
  const withdrawList = useMemo(() => {
    return GetWithdrawListQuery.data?.data?.data;
  }, [GetWithdrawListQuery.data?.data?.data]);

  const pendingWithdrawal = useMemo(() => {
    return companyAccount?.pendingWithdraws;
  }, [companyAccount?.pendingWithdraws]);

  const handleWithdraw = async () => {
    const { amount: withdrawAmount } = withdrawData;
    if (
      Number(withdrawAmount) <= 0 ||
      Number(withdrawAmount) > (companyAccount?.availableAmount || 0)
    ) {
      enqueueSnackbar("Jumlah penarikan tidak valid.", {
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "error",
      });
      return;
    }

    dispatch(
      openDialog({
        title: "Konfirmasi",
        message:
          "Anda yakin untuk melakukan penarikan data sebesar " +
          formatCurrency(Number(withdrawAmount), "IDR") +
          " ?",
        secondaryBtn: {
          onClick: async () => {
            try {
              await createWithdrawMutation.mutateAsync({
                companyId: companyId || "",
                amount: Number(withdrawAmount),
              });
              navigate(`${RoutePath[ROUTE_NAME.WITHDRAW_OTP]}`);
            } catch (error) {
              if (isAxiosError(error) && error.response?.status === 400) {
                const msg = error.response?.data?.message || "";
                let toastMsg = "Telah terjadi kesalahan. Silahkan coba lagi.";
                switch (msg) {
                  case "requested-amount-limit":
                    toastMsg = "Minimum Pencairan adalah 10000.";
                    break;
                  case "exceed-available-amount":
                    toastMsg = "Jumlah pencairan melebihi saldo yang tersedia.";
                    break;
                  case "pending-withdrawal":
                    toastMsg = "Permintaan pencairan sedang diproses.";
                    break;
                  default:
                    break;
                }
                enqueueSnackbar(toastMsg, {
                  variant: "error",
                  ...DEFAULT_SNACKBAR_PROPS,
                });
              }
            }
          },
        },
      })
    );

    // try {
    //   await createWithdrawMutation.mutateAsync({
    //     companyId: companyId || "",
    //     amount: Number(withdrawAmount),
    //   });
    //   enqueueSnackbar("Penarikan dana berhasil diajukan.", {
    //     variant: "success",
    //   });
    //   setWithdrawAmount(""); // Reset field after successful withdrawal
    //   GetWithdrawListQuery.refetch(); // Refresh list after withdrawal
    // } catch (error) {
    //   enqueueSnackbar("Terjadi kesalahan saat mengajukan penarikan.", {
    //     variant: "error",
    //   });
    // }
  };

  // useEffect(() => {
  //   // Simulate fetching available balance from user data or API
  //   setAvailableAmount(userReducer.data.balance || 0);
  // }, [userReducer.data.balance]);

  return (
    <AppContainer>
      <Box sx={ContainerStyle}>
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
            onClick: () => {
              navigate(-1); // Navigate back
            },
          }}
          text={"Penarikan Dana"}
        />
        <Box
          mt={2}
          sx={{
            backgroundColor: "white",
            p: 3,
            borderRadius: "20px",
            width: "80%",
          }}
          boxShadow={2}
        >
          <Typography
            color={COLOR.neutral500}
            fontWeight={600}
            fontSize={20}
            mb={2}
          >
            Saldo Tersedia:{" "}
            {formatCurrency(companyAccount?.availableAmount || 0, "IDR")}
          </Typography>

          {pendingWithdrawal?.status !== DisbursementStatus.PENDING ? (
            <Box>
              <Box sx={FieldContainer}>
                <TextInput
                  title="Jumlah Penarikan"
                  required
                  textInputProps={{
                    placeholder: "Masukkan jumlah penarikan",
                    value: withdrawData.amount,
                    type: "number",
                    onChange: (e) => {
                      dispatch(setWithdrawData({ amount: e.target.value }));
                    },
                  }}
                  helper={{
                    color: COLOR.danger500,
                  }}
                />
              </Box>

              <Box sx={FieldContainer} mt={2}>
                <CustomButton
                  // disabled={withdrawFundsMutation.isLoading}
                  disabled={false}
                  sx={{ width: "100%" }}
                  onClick={handleWithdraw}
                >
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    display="flex"
                    justifyContent="center"
                  >
                    Ajukan Penarikan
                  </Typography>
                </CustomButton>
              </Box>
            </Box>
          ) : (
            <Box sx={FieldContainer}>
              <Typography
                fontWeight={600}
                fontStyle="italic"
                color={COLOR.neutral500}
              >
                Penarikan sedang diproses.
              </Typography>
              <Typography fontStyle="italic" color={COLOR.neutral500}>
                Tanggal:{" "}
                {pendingWithdrawal
                  ? dayjs(pendingWithdrawal?.createdAt).format(
                      "DD MMM YYYY hh:mm"
                    ) +
                    " " +
                    TIMEZONE_SHORTLABEL[
                      pendingWithdrawal?.timezone || "Asia/Jakarta"
                    ]
                  : ""}
              </Typography>
              <Typography fontStyle="italic" color={COLOR.neutral500}>
                Jumlah: {formatCurrency(pendingWithdrawal?.amount || 0, "IDR")}{" "}
              </Typography>
            </Box>
          )}
        </Box>

        <Box mt={4} width={"80%"}>
          <Typography fontWeight={700} fontSize={18} mb={2}>
            Riwayat Penarikan
          </Typography>
          <Box
            sx={{
              backgroundColor: "white",
              p: 2,
              borderRadius: "10px",
              boxShadow: 1,
            }}
          >
            {(withdrawList || []).length > 0 ? (
              withdrawList?.map((withdraw, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  py={1}
                  borderBottom="1px solid #ddd"
                >
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Typography fontSize={16}>
                      {dayjs(withdraw.createdAt)
                        .tz(withdraw.timezone || "Asia/Jakarta")
                        .format("DD MMM YYYY hh:mm")}{" "}
                      {TIMEZONE_SHORTLABEL[withdraw.timezone || "Asia/Jakarta"]}
                    </Typography>
                    <Typography fontWeight={700}>
                      {formatCurrency(withdraw.totalAmount, "IDR")}
                    </Typography>
                  </Box>
                  <Typography
                    fontSize={16}
                    color={disbursementStatusColorMap[withdraw.status]}
                  >
                    {disbursementStatusMap[withdraw.status]}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography fontStyle="italic" color={COLOR.neutral500}>
                Belum ada penarikan.
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </AppContainer>
  );
};

export default Withdraw;
