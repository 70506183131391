import { useMemo, useEffect, useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../../redux/reducers";
import { COLOR } from "../../../utils/color";
import TextInput from "../../../components/Forms/TextInput/TextInput";
import { useGetCompanyAccountByID } from "../../../query/queries";
import {
  useResendBankAccount,
  useUpsertBankAccount,
  useVerifyBankAccount,
} from "../../../query/mutations";
import { useNavigate } from "react-router-dom";
import { ContainerStyle } from "../../../styles/global.styles";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import SubMenuHeader from "../../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import { BankCode } from "../../../types/globalTypes";
import {
  setBankAccountData,
  initialState,
} from "../../../redux/reducers/bankAccount";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { formatCountdown } from "../../../utils/time";
import { enqueueSnackbar } from "notistack";
import { DEFAULT_SNACKBAR_PROPS } from "../../../utils/constant";
import { isAxiosError } from "axios";

const BankAccountOTP = () => {
  const [otp, setOtp] = useState("");
  const [countdown, setCountdown] = useState(60 * 60); // 30 seconds countdown
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [initialData, setInitialData] = useState(initialState.bankAccountData);
  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const { bankAccountData } = useSelector(
    (state: RootReducerState) => state.bankAccountReducer
  );

  const resendBankAccount = useResendBankAccount();
  const verifyBankAccount = useVerifyBankAccount();

  const companyId = userReducer.data.companyId;
  const companyAccountQuery = useGetCompanyAccountByID(companyId || "");
  const companyAccount = useMemo(() => {
    return companyAccountQuery.data?.data?.data;
  }, [companyAccountQuery.data?.data?.data]);

  useEffect(() => {
    if (companyAccountQuery.isFetched && companyAccount) {
      const difference = companyAccount.verificationTokenExpiry - Date.now();
      if (difference > 0) {
        setCountdown(Math.ceil(difference / 1000));
      } else {
        setIsResendDisabled(false);
        setCountdown(0);
        companyAccountQuery.refetch();
      }
    }
  }, [companyAccount, companyAccountQuery.isFetched]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (isResendDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsResendDisabled(false);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown, isResendDisabled]);

  const handleResend = useCallback(async () => {
    try {
      await resendBankAccount.mutateAsync({
        companyId: companyId || "",
      });
      enqueueSnackbar(`Email Kode OTP telah dikirimkan kembali`, {
        variant: "success",
        ...DEFAULT_SNACKBAR_PROPS,
      });

      setIsResendDisabled(true); // Disable resend button again
      setCountdown(60 * 60); // Reset countdown
      companyAccountQuery.refetch();
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 400) {
        const msg = error.response?.data?.message || "";
        let toastMsg = "Telah terjadi kesalahan. Silahkan coba lagi.";
        switch (msg) {
          case "token-still-active":
            toastMsg = "Kode OTP masih aktif. Silahkan coba lagi.";
            break;
          case "verified-bank-account":
            toastMsg = "Akun bank sudah terverifikasi.";
            break;
          default:
            break;
        }
        enqueueSnackbar(toastMsg, {
          variant: "error",
          ...DEFAULT_SNACKBAR_PROPS,
        });
      }
    }
  }, [companyAccountQuery, companyId, resendBankAccount]);

  const handleOTPSubmit = async () => {
    try {
      await verifyBankAccount.mutateAsync({
        companyId: companyId || "",
        token: otp,
      });
      enqueueSnackbar(`Permintaan Verifikasi Akun Bank Berhasil`, {
        variant: "success",
        ...DEFAULT_SNACKBAR_PROPS,
      });
      navigate(-1);
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 400) {
        const msg = error.response?.data?.message || "";
        let toastMsg = "Telah terjadi kesalahan. Silahkan coba lagi.";
        switch (msg) {
          case "invalid-token":
            toastMsg = "Kode OTP tidak valid. Silahkan coba lagi.";
            break;
          case "expired-token":
            toastMsg = "Kode OTP sudah kadaluarsa. Silahkan coba lagi.";
            break;
          case "verified-user":
            toastMsg = "Akun bank sudah terverifikasi.";
            break;
          default:
            break;
        }
        enqueueSnackbar(toastMsg, {
          variant: "error",
          ...DEFAULT_SNACKBAR_PROPS,
        });
      }
    }
  };

  const initializeData = useCallback(() => {
    const latestData = companyAccount
      ? {
          accountNumber: companyAccount?.accountNumber,
          accountName: companyAccount?.accountName,
          bankCode: companyAccount?.bankCode,
          bankName: companyAccount?.bankName,
          bankInquiryStatus: companyAccount?.bankInquiryStatus,
        }
      : { ...initialState.bankAccountData };
    dispatch(setBankAccountData(latestData));
    setInitialData(latestData);
  }, [dispatch, companyAccount]);
  useEffect(initializeData, [initializeData]);

  return (
    <AppContainer>
      <Box sx={ContainerStyle}>
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
            onClick: () => navigate(-1),
          }}
          text={"Verifikasi Akun Bank"}
        />
        <Box py={4} width={"80%"} display="flex" flexDirection="column" gap={3}>
          <Typography variant="h6" textAlign="center" color={COLOR.neutral700}>
            Masukkan Kode OTP
          </Typography>
          <TextInput
            title="Kode OTP"
            required
            textInputProps={{
              type: "text",
              inputMode: "numeric",
              maxLength: 6,
              placeholder: "Masukkan 6 digit kode OTP",
              value: otp,
              onChange: (e) => setOtp(e.target.value),
            }}
            // helper={{
            //   text: error,
            //   color: error ? COLOR.danger500 : undefined,
            // }}
          />
          <Typography
            variant="body2"
            textAlign="center"
            color={isResendDisabled ? COLOR.neutral500 : COLOR.primary500}
            onClick={!isResendDisabled ? handleResend : undefined}
            style={{ cursor: isResendDisabled ? "default" : "pointer" }}
          >
            {isResendDisabled
              ? `Kirim ulang dalam ${formatCountdown(countdown)}`
              : "Kirim ulang kode"}
          </Typography>
          <CustomButton
            disabled={!otp || otp.length < 6}
            onClick={handleOTPSubmit}
            sx={{ width: "100%" }}
          >
            <Typography
              variant="body1"
              fontWeight={500}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              Verifikasi
            </Typography>
          </CustomButton>
        </Box>
      </Box>
    </AppContainer>
  );
};

export default BankAccountOTP;
