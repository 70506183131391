import { Box, SxProps, Typography } from "@mui/material";
import { useState } from "react";
import { Theme } from "@emotion/react";
import OpacityButton from "../../../components/OpacityButton/OpacityButton";
import EyeIcon from "../../../assets/svg/EyeOpenIcon";
import EyeClosedIcon from "../../../assets/svg/EyeClosedIcon";
import { formatNumber } from "../../../utils/helper";
import { COLOR } from "../../../utils/color";
import MenuButton from "../../../components/MenuButton/MenuButton";
import WalletIcon from "../../../assets/svg/WalletIcon";
import { Separator } from "@mdxeditor/editor";
import TicketSeparator from "../../../components/TicketSeparator/TicketSeparator";
import { FaArrowRight } from "react-icons/fa";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import {
  bankAccountColorMap,
  BankAccountStatus,
  bankAccountStatusMap,
} from "../../../types/globalTypes";
import { ROUTE_NAME, RoutePath } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";

interface WithdrawPropsI {
  amount: number;
  // onClickTransaction: React.MouseEventHandler<HTMLDivElement>;
  onClickWithdraw: React.MouseEventHandler<HTMLDivElement>;
  // onClickProduct: React.MouseEventHandler<HTMLDivElement>;
  // onClickBusinessProfile: React.MouseEventHandler<HTMLDivElement>;
  sx?: SxProps<Theme>;
  bankInquiryStatus?: BankAccountStatus;
}

const WithdrawCard: React.FC<WithdrawPropsI> = (props) => {
  const [hideAmount, setHideAmount] = useState<boolean>(false);
  const navigate = useNavigate();
  const BUTTON_LIST = [
    {
      IconElement: WalletIcon,
      primaryColor: COLOR.warning500,
      secondaryColor: COLOR.warning50,
      id: "withdraw",
      onClick: props.onClickWithdraw,
    },
  ];

  const textById: Record<string, string> = {
    transaction: "Transaksi",
    withdraw: "Pencairan",
    product: "Produk",
    profile: "Profil Usaha",
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        p: 3,
        borderRadius: "20px",
        wordBreak: "break-all",
        ...props.sx,
      }}
      boxShadow={2}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="start"
        alignItems={"center"}
        gap={1}
        pb={2}
      >
        <Typography color={COLOR.neutral500} fontWeight={600} fontSize={14}>
          Saldo Saat Ini
        </Typography>
        <OpacityButton
          sx={{ height: "24px" }}
          onClick={() => setHideAmount((prev) => !prev)}
        >
          {hideAmount ? (
            <EyeIcon color={COLOR.neutral400} />
          ) : (
            <EyeClosedIcon color={COLOR.neutral400} />
          )}
        </OpacityButton>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        pb={2}
      >
        <Typography fontWeight={800} fontSize={25} pt={1}>
          {hideAmount ? "* * * * *" : `Rp ${formatNumber(props.amount)}`}
        </Typography>
        <MenuButton
          key={"withdraw"}
          icon={<WalletIcon color={COLOR.warning500} />}
          color={COLOR.warning50}
          text={"Pencairan"}
          onClick={props.onClickWithdraw}
        />
      </Box>
      <TicketSeparator separatorType="solid" paddingX={0} />
      {props.bankInquiryStatus && (
        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems="center"
          onClick={() => {
            navigate(`${RoutePath[ROUTE_NAME.BANK_ACCOUNT]}`);
          }}
        >
          <Typography flex={1.5} fontWeight={500} fontSize={12}>
            {`Status Akun Bank:`}
          </Typography>
          <Box
            display="flex"
            flexDirection={"row"}
            justifyContent="space-between"
            gap={1}
          >
            <Typography
              flex={1}
              color={bankAccountColorMap[props.bankInquiryStatus]}
              fontWeight={500}
              fontSize={12}
            >
              {bankAccountStatusMap[props.bankInquiryStatus]}
            </Typography>
            <Box display="flex" alignItems="center">
              <IoIosArrowForward fontSize={12} color={COLOR.neutral400} />
            </Box>
          </Box>
        </Box>
      )}

      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        {BUTTON_LIST.map((button) => (
          <MenuButton
            key={button.id}
            icon={<button.IconElement color={button.primaryColor} />}
            color={button.secondaryColor}
            text={textById[button.id]}
            onClick={button.onClick}
          />
        ))}
      </Box> */}
    </Box>
  );
};

export default WithdrawCard;
