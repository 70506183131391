const TRANSACTION_FEE = 1000;
const VAT_RATE = 11;
export const QRIS_TAX = 0.7;

export const getTransactionTax = (
  amount: number,
  taxRate: number,
  hasVAT: boolean
): { amount: number; adminFee: number; total: number } => {
  if (!amount) return { amount: 0, adminFee: 0, total: 0 };
  const accumulativeTaxRate =
    taxRate + (VAT_RATE / 100) * (hasVAT ? 1 : 0) * taxRate;
  const taxDivider = 1 - accumulativeTaxRate / 100;
  const totalWithFee = amount + TRANSACTION_FEE;
  const total = Math.round(totalWithFee / taxDivider);
  const adminFee = total - amount;
  return { amount, adminFee, total };
};
